<template>
  <div v-if="Array.isArray(places) && places[0]">
    <div class="card" v-for="tango in pageOfItems" :key="tango.id">
      <Tango :tango="tango" />
    </div>
    <div class="card">
      <JwPagination :items="places" @changePage="onChangePage"></JwPagination>
    </div>
  </div>
  <div v-else>
    <p>{{ $t('no_tango_places_for_your_selection') }}</p>
  </div>
</template>
<script>
// import { mapGetters } from 'vuex';
import Tango from './Tango'
import JwPagination from '../core/JwPagination'
// import NavbarPeople from '@/components/core/NavbarPeople';

export default {
  name: 'PlaceList',
  components: {
    // NavbarPeople,
    Tango,
    JwPagination,
  },
  data() {
    return {
      // test: 100,
      // message: this.$store.state.places.message
      pageOfItems: [],
    }
  },
  computed: {
    // ...mapGetters([
    //   'productItems'
    // ])
    places() {
      let places = this.$store.state.places.places
      
      return places
    },
  },
  methods: {
    getImgPhoto(obj) {
      return obj.photo
    },
    onChangePage(pageOfItems) {
      // update page of items
      this.pageOfItems = pageOfItems
    },
  },
  created() {
    try {
//      this.$store.dispatch('getPlaces').then(() => {
//        console.log('PlaceList created places ', this.$store.state.places.places)
//      })
    } catch (err) {
      throw new Error(err)
    }      
  },
}
</script>

<style>
.card {
  background-color: grey !important;
}
</style>
