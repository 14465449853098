import axios from 'axios'
import router from '@/router'
import {
 
  parse,
} from 'date-fns'

//import i18n from '@/i18n'


const getDefaultState = () => {
  return {
    dancerChoice: 3,
    dancers: [],
    dancer: {},
    pet: {},
    taker: {},
    notes: [],
    cities: [],
    selectedCountry: '',
    selectedCity: '',
    selectedRegion: '',
    message: '',
    selectedFrom: new Date(),
    selectedRequests: false,
    yoe: '0'
  }
}
const state = getDefaultState()

const mutations = {
  resetProductState (state) {
    Object.assign(state, getDefaultState())
  },  
  UPDATE_PRODUCT_ITEMS(state, payload) {
    state.productItems = payload
  },
  UPDATE_DANCERS(state, payload) {
    state.dancers = payload
    state.message = ''
  },
  UPDATE_DANCER(state, payload) {
    state.dancer = payload
    state.message = ''
  },
  UPDATE_TAKER_NOTES(state, payload) {
    state.notes = payload
    state.message = ''
  },
  UPDATE_TAKER(state, payload) {
    state.taker = payload
    state.message = ''
  },
  setPet(state, payload) {
    state.pet = payload
  },  
  setHighPrice(state, event) {
    state.highPrice = event
  },
  setMessage(state, event) {
    state.message = event
  },
  setSelectedCountry(state, event) {
    state.selectedCountry = event
  },
  setSelectedCity(state, event) {

    state.selectedCity = event
  },
  setSelectedRegion(state, event) {

    state.selectedRegion = event
  },  
  setDancerChoice(state, event) {

    state.dancerChoice = event
  },
  setSelectedFrom(state, event) {

    state.selectedFrom = event
    
  }, 
  setYoe(state, event) {

    state.yoe = event
    
  },
  setSelectedRequests(state, event) {
    state.selectedRequests = event
  },   
  setCreatedAt(state, event) {

    state.createdAt = event
    router.push('/petchoice')
  },
  UPDATE_CITIES(state, payload) {
    state.cities = payload
  },
}

const actions = {

  async getCities({ commit }) {
    await axios
      .get(`/api/animals/cities/` + state.selectedCountry)
      .then((response) => {
        commit('UPDATE_CITIES', response.data)

        // commit('setSelectedCity', state.cities[0])
      })
  },
  async getPetById({ commit }, id) {

    await axios
      .get('/api/animals/pet/' + JSON.stringify(id))
      .then((response) => {
        let pet = response.data
        // pet.id = response.data.id
        commit('setPet', pet)
        
        // router.push("/profileContact/");
      })
      
  },
  async getDancer({ commit }, id) {

    await axios
      .get('/api/dancers/dancer/' + JSON.stringify(id))
      .then((response) => {
        let dancer = response.data
        dancer.id = response.data.id
        commit('UPDATE_DANCER', dancer)

        // router.push("/profileContact/");
      })
  },
  async getTaker({ commit, dispatch }, id) {

    //const runResult = await new Promise((resolve, reject) => {
    try {
      axios.get('/api/taker/' + JSON.stringify(id)).then((response) => {

        let taker = response.data.account
        taker.id = response.data.id
        commit('UPDATE_TAKER', taker)

        dispatch('getNotes', taker.id)
      })
    } catch (error) {
      console.log('getTaker error', error)
      //reject(error);
    }
    //})
    //return runResult
  },
  async getRequestedDancers({ commit }) {
    //console.log('getRequestedDancers)')
    let search = {
      city: state.selectedCity,
      country: state.selectedCountry,
      role: state.dancerChoice,
      yoe: state.yoe,
      from: state.selectedFrom,
      region: state.selectedRegion
      //from: state.createdAt,
    }

    await axios
      .get(`/api/reqdancers/` + JSON.stringify(search))
      .then((response) => {
        commit('UPDATE_DANCERS', response.data)

      })
  },
  async getDancers({ commit }) {
    let search = {
      city: state.selectedCity,
      country: state.selectedCountry,
      region: state.selectedRegion,
      role: state.dancerChoice,
      yoe: state.yoe

      //from: state.createdAt,
    }

    await axios
      .get(`/api/dancers/` + JSON.stringify(search))
      .then((response) => {
        commit('UPDATE_DANCERS', response.data)

      })
  },
}

const getters = {
  dancers: (state) => state.dancers,
  selectedCity: (state) => state.selectedCity,
  selectedRegione: (state) => state.selectedRegione,
  selectedCountry: (state) => state.selectedCountry,
  selectedRequests: (state) => state.selectedRequests,
  yoe: (state) => state.yoe,
  selectedFrom: (state) => 
  //parse('01 12 2009', 'MM dd yyyy', new Date()))
    //parse(state.selectedFrom, 'MM-yyyy', new Date())
    parse(state.selectedFrom, 'MM-yyyy', new Date())
    //new Intl.DateTimeFormat('en-US', { dateStyle: 'long' }).format(
    //  new Date(state.selectedFrom) ) 
  ,
  selectedEmail: (state) => state.selectedEmail,
  selectedPhone: (state) => state.selectedPhone,
  productItems: (state) => state.productItems,
  productItemById: (state) => (id) => {
    return state.productItems((productItem) => productItem.id === id)
  },
}

const productModule = {
  state,
  mutations,
  actions,
  getters,
  }

export default productModule
