export default {
  "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Про що ця апа..."])},
  "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опис"])},
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дім"])},
  "home_img": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/assets/Home_page_ua_small_bright1.png"])},
  "pets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Улюбленці"])},
  "pet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Домашній улюбленць"])},
  "takers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опікуни"])},
  "taker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опікун"])},
  "owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Власник"])},
  "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ввійти"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скринька"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль"])},
  "forgot_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Забули пароль?"])},
  "change_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Змінити пароль"])},
  "signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зареєструватися"])},
  "photo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фото"])},
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пані/Пан"])},
  "full_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повне ім'я"])},
  "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Телефон"])},
  "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Країна"])},
  "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Місто"])},
  "choose_country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обрати країну"])},
  "your_interests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Хобі"])},
  "confirm_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Підтвердіть пароль"])},
  "profile_photo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Помилка загрузки фотографії"])},
  "profile_photo_tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фотографія для вашого профілю"])},
  "profile_photo_pet_tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фотографія вашої домашньої тварини"])},
  "field_is_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обов'язкове до заповнення"])},
  "use_another_photo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Будьласка, оберіть іншу фотографію"])},
  "ask_admin_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Будьласка, запитайте адміністратора"])},
  "ph_tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тільки цифри, не більше 10"])},
  "title_tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пані/Пан"])},
  "sign_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зареєструватися"])},
  "pswd_tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Має бути більш ніж 6 літер"])},
  "taker_owner_tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опікун бере улюбленця у Власника до себе на деякий час"])},
  "owner_taker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Власник, Опікун  чи Обидва"])},
  "both": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Обидва"])},
  "both_tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Власник та Опікун"])},
  "file_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фото обов'язкове"])},
  "password_taken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Такий пароль вже існує"])},
  "email_taken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цей мейл вже існує"])},
  "default_country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ukraine"])},
  "logged_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зареєструйтесь спочатку, будьласка"])},
  "owner_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ця дія тільки для Власника"])},
  "program_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Помилка у програмі, будьласка звʼяжіться з адміністратором "])},
  "new_pet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додати улюбленця"])},
  "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Видалити"])},
  "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["  з  "])},
  "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["  до  "])},
  "service_costs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вартість послуги/Винагорода"])},
  "add_request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додати запит для"])},
  "description_need": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опис потреби"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скасувати"])},
  "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Оновити"])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зберегти"])},
  "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редагувати"])},
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вийти"])},
  "needed_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Потрібно для"])},
  "pet_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ім'я вашого улюбленця"])},
  "physical_characteristics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фізичні характеристики"])},
  "needs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Потреби"])},
  "please_fill_out_fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Будь ласка, заповніть поля"])},
  "sorry_you_can_only_upload_files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bи можете завантажувати лише файли як:"])},
  "image_types_expected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Очікувані типи зображень:"])},
  "pet_interests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Звички вашого улюбленця"])},
  "interests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Звички"])},
  "Ukraine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Україна"])},
  "Germany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Німеччина"])},
  "Spain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Іспанія"])},
  "Switzerland": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Швейцарія"])},
  "Kyiv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Київ"])},
  "Odesa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Одеса"])},
  "Lviv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Львів"])},
  "Madrid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мадрід"])},
  "Barcelona": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Барселона"])},
  "Munchen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мюнхєн"])},
  "Lugano": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лугано"])},
  "Locarno": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Локарно"])},
  "Bern": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Берн"])},
  "Zurich": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Цюрих"])},
  "dogs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["   Собаки   "])},
  "birds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["   Птахи   "])},
  "cats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["   Коти   "])},
  "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["   Інші   "])},
  "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Підтримка"])},
  "get_in_touch_with_us_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зв'яжіться з нами за адресою"])},
  "vacation_now_and_next_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зараз +30 днів"])},
  "date_for_home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дати коли потрібен дім"])},
  "contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Контакти"])},
  "email_tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["не менше 3 символів"])},
  "no_pets_yet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Домашніх тварин ще немає"])},
  "no_takers_for_your_selection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Немає претендентів на ваш вибір"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пошук"])},
  "review_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review for"])},
  "leave_thank_you_note_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Залиште подяку для "])},
  "see_reviews_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дивіться відгуки для "])},
  "leave_review_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Залиште відгики для "])},
  "see_thank_you_notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дивіться подяки для "])},
  "price_pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Винагорода за весь період"])},
  "edit_your_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редагування свого профілю"])},
  "edit_your_pet_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редагуйте профіль вашого улюбленця"])},
  "add_your_pet_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додайте профіль вашого улюбленця"])},
  "wrong_dates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Невірні дати"])},
  "description_need_req": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опис потреби, вимагається"])},
  "thank_you_note_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подяка(и) для"])},
  "thank_you_empty_note_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Немає ще подяки для"])},
  "no_review_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No review(s) yet for"])},
  "description_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опис"])},
  "from_someone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["від"])},
  "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повернутися"])},
  "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" не знайдено"])},
  "kind_of_pet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Що це за тварина"])},
  "kind_of_pet_tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вид домашнього вихованця (собака, кіт, птаха для решти оберіть 'Інше')"])},
  "reset_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset password"])},
  "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Надіслати"])},
  "current_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поточний пароль"])},
  "new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Новий пароль"])},
  "confirmed_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Підтвердження паролю"])},
  "invalid_request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Невірний запит"])},
  "cannot_change_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не вдалося змінити пароль"])},
  "error_sending_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Помилка надсилання електронного листа"])},
  "error_with_typed_credentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Помилка з введеними даними"])},
  "email_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Електронну адресу не знайдено"])},
  "new_place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додай нове танго місце"])},
  "edit_my_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Редагуйте ваші данні"])},
  "add_place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Додай танго місце"])},
  "place_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опси танго місця"])},
  "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вулиця"])},
  "place_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Назва танго місця"])},
  "choose_request_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose month for your request"])},
  "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Region"])},
  "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Адреса:"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Leader"])}
}