import {
    Form as VeeForm,
    Field as VeeField,
    defineRule,
    configure,
    ErrorMessage,
   
  } from "vee-validate";
  import { localize } from '@vee-validate/i18n';
  
  //import { extend } from 'vee-validate';
  // import { required, min, max, email, url, numeric, confirmed, regex } from 'vee-validate/dist/rules.esm.js';
  import { required, confirmed, email } from "@vee-validate/rules";
 
    configure({
      generateMessage: localize({
        en: {
          messages: {
            required: 'Field is required',
            confirmed: 'Password confirmation does not match',
            min: 'Should be at least 0:{min} characters',
            max: 'Should be less 0:{max} characters',
            email: 'Invalid email',
            
            
          },
        },
        ua: {
          messages: {
            required: "Обов'язкове до заповнення",
            confirmed: 'Пароль повинен збігатися',
            min: 'Має бути більш ніж 0:{min} літери',
            max: 'Має бути менш ніж 0:{max} літери',
            email: 'Адреса має мати ...@...' ,
                     
          },
        },
      }),
    })

  export default {
    install(app) {
      app.component("VeeForm", VeeForm);
      app.component("VeeField", VeeField);
      app.component("ErrorMessage", ErrorMessage);
  
      defineRule("required", required);
      defineRule("email", email);
      defineRule("confirmed", confirmed);

      defineRule('min', (value, [min]) => {
        if (value && value.length < min) {
          // return `Should be at least ${min} characters`;
          return 
        }
      
        return true;
      }); 
    
      defineRule("max", (value, [max]) => {
        if (value && value.length > max) {
          // return `Should be less ${max} characters`;
          return 
        }
      
        return true;
      });
     
    },
  };