export default {
  "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What it is about:"])},
  "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
  "home_img": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/assets/Home_page_tango_color.png"])},
  "dancers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dancers"])},
  "pet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pet"])},
  "follower": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Follower"])},
  "tango_places": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Places"])},
  "leader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Leader"])},
  "lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Leader"])},
  "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
  "forgot_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot password?"])},
  "signup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign up"])},
  "change_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change password"])},
  "photo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Photo"])},
  "body_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Body Type"])},
  "full_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full name"])},
  "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
  "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
  "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
  "choose_country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose country"])},
  "your_interests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interests"])},
  "confirm_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm password"])},
  "profile_photo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid photo"])},
  "profile_photo_tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Photo"])},
  "add_request_tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add your request for dance partner for milonga/practica"])},
  "choose_request_month_tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose month when checkbox below is checked"])},
  "search_request_tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search in dancers requests in choosen month"])},
  "delete_profile_tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete permanently your profile with all your profile data"])},
  "add_tangoplace_tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add tango place if it wasn't yet added by others"])},
  "profile_availability_tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By checking availability you allow dancers to see your profile in their search"])},
  "profile_photo_pet_tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Photo of your pet"])},
  "field_is_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is required"])},
  "use_another_photo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please use another photo"])},
  "ask_admin_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please ask admin help"])},
  "ph_tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only numbers, no more then 10"])},
  "body_type_tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["skinny, big, small"])},
  "yoe_tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["years of tango experience"])},
  "yoe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Years of experience"])},
  "sign_up": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign Up"])},
  "pswd_tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Must be more then 6 chars"])},
  "lead_follower_tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leader, Follower or Both roles"])},
  "lead_follower": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leader, Follower or Both"])},
  "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Height(cm)"])},
  "height_tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Height in centimeters"])},
  "both": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Both"])},
  "both_tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leader and Follower"])},
  "file_required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File required"])},
  "password_taken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This password is taken"])},
  "email_taken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This email is taken"])},
  "default_country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ukraine"])},
  "logged_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have to be logged in first"])},
  "owner_only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have to be an owner of a pet for these actions"])},
  "program_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Program error please contact to admin"])},
  "new_request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add request"])},
  "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])},
  "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["  from  "])},
  "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["  to  "])},
  "service_costs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service Costs/Award from Owner"])},
  "add_request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add request for"])},
  "description_need": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description of need"])},
  "price_pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Award for all period"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
  "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update"])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
  "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log out"])},
  "needed_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Needed for"])},
  "pet_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pet name"])},
  "physical_characteristics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Physical characteristics"])},
  "needs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Needs"])},
  "please_fill_out_fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please, fill out fields"])},
  "sorry_you_can_only_upload_files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry you can only upload files: "])},
  "image_types_expected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image types expected:"])},
  "pet_interests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pet's habits"])},
  "interests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Habits"])},
  "UKRAINE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UKRAINE"])},
  "GERMANY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GERMANY"])},
  "SPAIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SPAIN"])},
  "SWITZERLAND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SWITZERLAND"])},
  "Kyiv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kyiv"])},
  "Odesa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odesa"])},
  "Lviv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lviv"])},
  "Madrid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Madrid"])},
  "Barcelona": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Barcelona"])},
  "Munchen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Munchen"])},
  "Lugano": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lugano"])},
  "Locarno": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Locarno"])},
  "Bern": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bern"])},
  "Zurich": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurich"])},
  "dogs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["  Dogs  "])},
  "birds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["  Birds  "])},
  "cats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["  Cats  "])},
  "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["  Other  "])},
  "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support"])},
  "get_in_touch_with_us_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get in touch with us at"])},
  "vacation_now_and_next_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Now and next month"])},
  "date_for_home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dates when home needed"])},
  "contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacts"])},
  "email_tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["at least 3 chars"])},
  "no_pets_yet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No pets yet"])},
  "no_tango_places_for_your_selection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Tango places for your selection yet"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
  "review_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review for"])},
  "see_reviews_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See reviews for"])},
  "leave_review_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leave review for"])},
  "leave_thank_you_note_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leave thank you note for "])},
  "see_thank_you_notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See thank you notes for "])},
  "wrong_dates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wrong dates"])},
  "description_need_req": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description of need, required"])},
  "edit_your_pet_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit your pet profile"])},
  "add_your_pet_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add your pet profile"])},
  "edit_your_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit your profile"])},
  "thank_you_note_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you note(s) for"])},
  "thank_you_empty_note_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No thank you note(s) yet for"])},
  "no_review_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No review(s) yet for"])},
  "description_note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
  "from_someone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["from"])},
  "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
  "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Not found"])},
  "kind_of_pet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What kind of pet"])},
  "kind_of_pet_tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kind of pet (dog, cat, bird for the rest choose 'Other')"])},
  "reset_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset password"])},
  "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
  "current_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current password"])},
  "new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New password"])},
  "confirmed_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmed password"])},
  "invalid_request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid request"])},
  "cannot_change_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can not change password"])},
  "error_sending_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error sending email"])},
  "error_with_typed_credentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error with typed credentials"])},
  "email_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email not found"])},
  "years_of_tango_experience_more _then": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Years of tango experience, more then"])},
  "search_for_dance_partner_for_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for dance partner for month"])},
  "request_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request for milonga or practice"])},
  "add_your_request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add your request, in city or region"])},
  "new_place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add tango place"])},
  "edit_my_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit my profile"])},
  "delete_my_profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete my profile"])},
  "add_place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add tango place"])},
  "place_desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description of tango place"])},
  "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Street"])},
  "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Region"])},
  "place_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name of tango place"])},
  "choose_request_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose month to search in dancers requests"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location:"])},
  "requests_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dancer requests for"])},
  "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dancer Role"])},
  "pethome_tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check this app also"])},
  "dancers_tip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add your tango request for a partner \n with a desirable tango experience in years, \n choose month from calendar.\n Choose place country(required), \n region, city where you are going to practice/milonga.\n After you added your request tango dancers \n can see your request and email \n you or call you if you left your phone. \n     You can search tango dancers in country(required) \n and city/region who put themselves \n available(checkbox while signing up) "])}
}