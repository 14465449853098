<template>
  <aside>

    <div class="aside-block">
      
        <Form @submit="search" >

          <div class="form-group">
            <p><span for="country"><strong>{{ $t('country') }}</strong></span></p>
          <Field v-slot="{ field, errors }" v-model="tangoCountry" class="form-control" @change="updateCountry" name="country" rules="required|min:3">
            <input v-bind="field" name="country" class="form-control" />
            <div v-if="errors[0]" class="error-feedback">{{errors[0]}}</div>
          </Field>   


          </div>

          <div class="form-group">
            <p><span for="city"><strong>{{ $t('city') }}</strong></span></p>
            <Field name="city" type="text" @change="updateCity" v-model="tangoCity" class="form-control" rules="min:3"/>

          </div>
          <div class="form-group">
            <p><span for="region"><strong>{{ $t('region') }}</strong></span></p>
            <Field name="region" type="text" v-model="tangoRegion" @change="updateRegion" class="form-control" rules="min:3"/>

          </div>

          <div class="form-group">
            <p><span for="street"><strong>{{ $t('street') }}</strong></span></p>
            <Field name="street" type="text" v-model="street" class="form-control" rules="min:3"/>

          </div>          
          

          <div class="form-group">
            <p><span for="name"><strong>{{ $t('name') }}</strong></span></p>
            <Field name="name" type="text" v-model="name" class="form-control" rules="min:3"/>

          </div>          

          <span class="input-group-btn" @click="search()">
            <button class="badge badge-dark" style="font-size: .87em;" type="submit" id="btn-search">
              {{ $t('search') }}
            </button>
          </span>
        </Form>
      
    </div>
    <div class="aside-block">
      <strong>{{ $t('support') }}</strong>
      <p>
        {{ $t('get_in_touch_with_us_at') }}:
        <strong >s-nom-tango@krucha.com</strong>
      </p>
      <p v-tooltip="`${$t('pethome_tip')}`">
        
        <a href="https://pethome.krucha.com"  target="_blank" rel="noopener" style="color: #485fc7;"><strong>Temporary home for pets</strong></a>
      </p>           
    </div>
  </aside>
</template>

<script>
//import i18n from '@/i18n'
import { directive } from 'vue-tippy'
import { required, min, email} from '@vee-validate/rules';


import { Form, Field, defineRule } from 'vee-validate'
defineRule('required', required);
defineRule('min', min);
defineRule('email', email);

export default {
  name: 'SidebarPerson',
  components: {
    Form,
    Field

  },
  directives: {
    tippy: directive,
  },  
  data() {
    return {
      min: 0,
      max: 5000,
      isFetching: true,
      name: '',
      street: '',
      countries: ['UKRAINE', 'SWITZERLAND', 'GERMANY', 'SPAIN'],
      tangoRegion: this.$store.state.places.tangoRegion,
      tangoCountry: this.$store.state.places.tangoCountry,
      tangoCity: this.$store.state.places.tangoCity,
    }
  },
  methods: {
    updateError() {
      if (this.email.length > 0 && this.email.length < 4) {
        return
      }
      if (this.phone.length > 0 && this.phone.length < 4) {
        return
      }
      this.search() 
    },
    translateCities(cities) {
      let filledCities= [];
      for(let i=0; i<cities.length;i++) { 
        //console.log('translateCities', this.$t(cities[i]))
        filledCities[i] = {'translation':this.$t(cities[i]), 'value':cities[i]};
      } 
      return filledCities
    }, 
    getError() {
      return this.$store.state.profiles.message
    },
    async search() {
      try {
        let placesSearch = {
          
          region: this.$store.state.places.tangoRegion,
          city: this.$store.state.places.tangoCity,
          country: this.$store.state.places.tangoCountry,
          street: this.street,
          name: this.name,
        }
        await this.$store.dispatch('search', placesSearch).then(() => {})
      } catch (err) {
        throw new Error(err)
      }
    },
    changeTakers() {
      try {
        let location = {
          city: this.$store.state.places.tangoCity,
          country: this.$store.state.places.tangoCountry,
        }

        this.$store.dispatch('getTakers', location).then(() => {
        })
      } catch (err) {
        throw new Error(err)
      }
    },
    updateCountry($event) {
      this.$store.commit('setTangoCountry', $event.target.value)
      
    },
    updateCity($event) {
      this.$store.commit('setTangoCity', $event.target.value)
 
    },
    updateRegion($event) {
      this.$store.commit('setTangoRegion', $event.target.value)
 
    },
  },
  mounted() {
    
    try {
      if (this.$store.state.places.tangoCountry.length == 0) {
        this.$store.commit('setTangoCountry', this.$store.state.user.profile.account.country)
        this.tangoCountry = this.$store.state.places.tangoCountry
        this.$store.commit('setTangoRegion', this.$store.state.user.profile.account.region)
        this.tangoRegion = this.$store.state.places.tangoRegion
        this.$store.commit('setTangoCity', this.$store.state.user.profile.account.city)
        this.tangoCity = this.$store.state.places.tangoCity
      }
      this.$store.dispatch('getPlaces').then(() => {
      })
    } catch (err) {
      throw new Error(err)
    }
  },
}
</script>

<style lang="css">
.aside-block {
  padding: 10px 0;
  border-bottom: 1px solid #eee;
  font-size: 15px;
}
.min {
  float: left;
}
.max {
  float: right;
}
.aside-block h4 {
  margin-bottom: 10px;
}
.checkbox-control {
  position: relative;
  display: inline-block;
}
.checkbox-box {
  width: 18px;
  height: 18px;
  border: 2px solid #131313;
  position: relative;
  cursor: pointer;
  float: left;
  top: 2px;
  margin-right: 10px;
}
input[type='checkbox'] {
  opacity: 0;
  position: absolute;
}
input[type='checkbox']:hover ~ .checkbox-box,
input[type='checkbox']:focus ~ .checkbox-box,
input[type='checkbox']:checked ~ .checkbox-box {
  border-color: #5044ff;
}
input[type='checkbox']:checked ~ .checkbox-box::before {
  content: '';
  position: absolute;
  width: 10px;
  height: 10px;
  top: 2px;
  left: 2px;
  background: #5044ff;
}
</style>
