<template>
  <aside>
    <div>
    <p>
    <input
      id="one"
      :value="2"
      type="radio"
      :checked="dancerChoice == '2'"
      @input="updateDancerChoice($event)"
    />
    <strong>   {{ $t('follower') }}   </strong>
    </p>
    </div>
    <div>
    <p>
    <input
      id="two"
      :value="1"
      type="radio"
      :checked="dancerChoice == '1'"
      @input="updateDancerChoice($event)"
    />
    <strong>   {{ $t('leader') }}   </strong>
    </p>
    </div>
    
    <p>
    <input
      id="three"
      :value="3"
      type="radio"
      :checked="dancerChoice == '3'"
      @input="updateDancerChoice($event)"
    />
    <strong>  {{ $t('both') }}   </strong>
    </p>
    
    <div class="aside-block">

          <div class="form-group">
            <p><span for="country"><strong>{{ $t('country') }}</strong></span></p>
          <Field v-slot="{ field, errors }" v-model="selectedCountry"  class="form-control" @change="updateCountry" name="country" rules="required|min:3">
            <input v-bind="field" name="country" class="form-control" />
            <div v-if="errors[0]" class="error-feedback">{{errors[0]}}</div>
          </Field>   


          </div>

          <div class="form-group">
            <p><span for="city"><strong>{{ $t('city') }}</strong></span></p>
            <Field name="city" type="text" @change="updateCity" v-model="selectedCity" class="form-control" rules="min:3"/>

          </div>
          <div class="form-group">
            <p><span for="region"><strong>{{ $t('region') }}</strong></span></p>
            <Field name="region" type="text" @change="updateRegion" v-model="selectedRegion" class="form-control" rules="min:3"/>

          </div>

          <div class="form-group">
            <p><span for="yoe"><strong>{{ $t('years_of_tango_experience_more _then') }}</strong></span></p>
            <Field
              name="yoe"
              as="select"
              class="form-control"
              @change="updateYears"
              v-model="yoe"
            >
              <option v-for="year in years" :value="year" :key="year">{{year}}
                
              </option>
            </Field>
            
          </div> 
          
          <div v-if="this.selectedRequests" class="form-group" style="padding-left:10px;">
            
            <p v-tooltip="`${$t('choose_request_month_tip')}`" >
            
            <label for="dateFrom"><strong>{{ $t('choose_request_month') }}</strong></label>
            
            <datepicker v-model="dateFrom" 
            minimum-view="month"  />
            <input 
              type="hidden"
              name="dateFrom"
              @change="updateDancers"
            />
            
            </p>
            
          </div>
           
                   
          <div class="form-group" style="padding-left:10px;">
            
            <span v-tooltip="`${$t('search_request_tip')}`" >
            
            <label class="checkbox-control" >
              <input
                type="checkbox" v-model="selectedRequests" @change="isCheckedRequest" 
                
              /><strong >Search dancers requests</strong>
            <div class="checkbox-box" ></div>
            </label>
           
            </span>
            
          </div>
          
        

    </div>

    <div class="aside-block">
      <strong>{{ $t('support') }}</strong>
      <p>
        {{ $t('get_in_touch_with_us_at') }}:
        <strong >s-nom-tango@krucha.com</strong>
      </p>
      <p v-tooltip="`${$t('pethome_tip')}`">
        
        <a href="https://pethome.krucha.com" target="_blank" rel="noopener" style="color: #485fc7;" ><strong>Temporary home for pets</strong></a>
      </p>      
    </div>
  </aside>
</template>

<script>
import { mapGetters } from 'vuex'
import { Field, defineRule} from 'vee-validate'
import { required, min} from '@vee-validate/rules';
import { directive } from 'vue-tippy'
import datepicker from 'vue3-datepicker'
defineRule('required', required);
defineRule('min', min);

export default {
  name: 'Sidebar',
  components: {
    Field,
    datepicker
  }, 
  directives: {
    tippy: directive,
  },     
  data() {
    return {
      min: 0,
      max: 5000,
      isFetching: true,
      selectedCity: this.$store.state.product.selectedCity,
      selectedCountry: this.$store.state.product.selectedCountry,
      countries: ['UKRAINE', 'SWITZERLAND', 'GERMANY', 'SPAIN'],
      years: [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15],
      
      yoe: this.$store.state.product.yoe,
      selectedRegion: this.$store.state.product.selectedRegion,
      dateFrom: this.$store.getters.selectedFrom,
      selectedRequests: this.$store.state.product.selectedRequests
    }
  },
  computed: {
    ...mapGetters(['selectedFrom']),
    cities() {
      return this.translateCities(this.$store.state.product.cities)
    },

    dancerChoice() {
      return this.$store.state.product.dancerChoice
    },
    message() {
      return this.$store.state.product.message
    },
  },
  methods: {
    //async getCities() {
    // await this.$store.state.product.cities
    //},
    translateCities(cities) {
      let filledCities= [];
      for(let i=0; i<cities.length;i++) { 
        //console.log('translateCities', this.$t(cities[i]))
        filledCities[i] = {'translation':this.$t(cities[i]), 'value':cities[i]};
      } 

      return filledCities
    },    
    async changeDancers() {
      try {
        //console.log("changeDancers")
        await this.$store.dispatch('getDancers').then(() => {

        })
      } catch (err) {
        throw new Error(err)
      }
    },
    isCheckedRequest($event) {
      //console.log('isCheckedRequest', $event.target.checked)
      this.$store.commit('setSelectedRequests', $event.target.checked)
      if (!this.selectedRequests) {

        this.changeDancers()
      } 
    },
    async updateDancers() {
      try {

        if (this.selectedRequests) {
          await this.$store.dispatch('getRequestedDancers').then(() => {
          
          })
        }
        else {
          this.changeDancers()
        }  
      } catch (err) {
        throw new Error(err)
      }
    },
    updateYears($event) {
      this.$store.commit('setYoe', $event.target.value)
      this.updateDancers()      
    },
    updateCountry($event) {
      if (!this.selectedCountry) {
        return false
      } 
      this.$store.commit('setSelectedCountry', $event.target.value)
      this.updateDancers()
    },
    updateCity($event) {
      this.$store.commit('setSelectedCity', $event.target.value)
      this.updateDancers()
    },
    updateRegion($event) {
      this.$store.commit('setSelectedRegion', $event.target.value)
      this.updateDancers()
    },    
    updateDancerChoice($event) {
      this.$store.commit('setDancerChoice', $event.target.value)
      this.updateDancers()
    },
    currentDate() {
      const current = new Date()
      const date =
        current.getFullYear() +
        '-' +
        (current.getMonth() + 1) +
        '-' +
        current.getDate()
      // const time = current.getHours() + ":" + current.getMinutes() + ":" + current.getSeconds();
      // const dateTime = date +' '+ time;
      // const date = `${current.getDate()}/${current.getMonth()+1}/${current.getFullYear()}`;
      current.setDate(new Date(date).getDate() - 7)
      return this.$store.state.product.formatDate(current)
    },
    formatDate(date) {
      return new Intl.DateTimeFormat('en-US', { dateStyle: 'long' }).format(
        new Date(date)
      )
    },
    formateDateFrom() {

      const yyyy = this.dateFrom.getFullYear();
      let mm = this.dateFrom.getMonth() + 1; // month is zero-based
      if (mm < 10) mm = '0' + mm;
      const formatted = mm + '-' + yyyy;
      this.$store.commit('setSelectedFrom', formatted)
           
    },
  },
  watch: {
    dateFrom() {
      this.formateDateFrom()
      
      this.updateDancers()
    }  
  },  

  created() {
    console.log('created this.selectedRequests ', this.selectedRequests, this.$store.state.product.selectedRequests)
    if (this.$store.state.product.dancers.length == 0) {
      
      this.$store.commit('setSelectedCountry', this.$store.state.user.profile.account.country)
      this.selectedCountry = this.$store.state.product.selectedCountry
      this.$store.commit('setSelectedRegion', this.$store.state.user.profile.account.region)
      this.selectedRegion = this.$store.state.product.selectedRegion
      this.$store.commit('setSelectedCity', this.$store.state.user.profile.account.city)
      this.selectedCity = this.$store.state.product.selectedCity
      this.$store.commit('setYoe', this.yoe)
      this.yoe = this.$store.state.product.yoe
      this.formateDateFrom()
    }
    try { 
      //console.log("dancerlist created") 
      this.updateDancers()

    } catch (err) {
      throw new Error(err)
    }        
  },
}
</script>

<style lang="css">
.v3dp__datepicker input {
  width: 120px !important;;
  height: 38px !important;;
}
.data-v-tooltip {
  
  --v-tooltip-left: 90% !important;;
} 
.aside-block {
  padding: 40px 0;
  border-bottom: 1px solid #eee;
  font-size: 15px;
}
.min {
  float: left;
}
.max {
  float: right;
}
.aside-block h4 {
  margin-bottom: 10px;
}
.checkbox-control {
  position: relative;
  display: inline-block;
}
.checkbox-box {
  width: 18px;
  height: 18px;
  border: 2px solid #131313;
  position: relative;
  cursor: pointer;
  float: left;
  top: 2px;
  margin-right: 10px;
}
input[type='checkbox'] {
  opacity: 0;
  position: absolute;
}
input[type='checkbox']:hover ~ .checkbox-box,
input[type='checkbox']:focus ~ .checkbox-box,
input[type='checkbox']:checked ~ .checkbox-box {
  border-color: #5044ff;
}
input[type='checkbox']:checked ~ .checkbox-box::before {
  content: '';
  position: absolute;
  width: 2px;
  height: 10px;
  top: 2px;
  left: 2px;
  background: #5044ff;
}
</style>
