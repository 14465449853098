import { createApp } from 'vue'
// import Vue from 'vue'

import App from './App.vue'
import './../node_modules/bulma/css/bulma.css'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css'
import '/static/plugin/validation.js'
//import '/static/plugin/validation3.js'
import tooltip from "./directives/tooltip.js";
import "@/assets/tooltip.css";
// import datePicker from 'vue-bootstrap-datetimepicker';
import { func } from '/static/api'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
library.add(fas)
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
//import JwPagination from 'jw-vue-pagination';
//import JwPagination from '../core/JwPagination';
import { dom } from '@fortawesome/fontawesome-svg-core'
dom.watch()
import router from './router'
import store from './store'
import './registerServiceWorker'
import i18n from './i18n'
//import { useStore } from 'vuex';

// new Vue({
//     el: '#app',
//     router,
//     components: { App },
//     store: store,
//     beforeCreate() { this.$store.commit('initialiseStore');},
//     template: '<App/>'
//   })

const appRoot = createApp(App)
appRoot.config.globalProperties.$func = func
appRoot.component('font-awesome-icon', FontAwesomeIcon)
//appRoot.component('jw-pagination', JwPagination);
appRoot.component('datePicker', 'datePicker')
appRoot.use(store).use(router)
store.dispatch('initialiseStore')

// const appRoot = createApp({
//     data: () => ({
//         beforeCreate() {
//             this.$store.commit('initialiseStore')
//             console.log("beforeCreate")
//         }
//     }),

// }).component('font-awesome-icon', FontAwesomeIcon).use(store).use(router);
// beforeCreate: () => this.$store.commit('initialiseStore');

appRoot.directive("tooltip", tooltip);
appRoot.use(i18n)
appRoot.mount('#app')

// beforeCreate: {
//     this.$store.commit('initialiseStore')
// }
