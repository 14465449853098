<template>
  <nav class="navbar navbar-default navbar-fixed-top" style="background-color:white; width:auto">
    <div id="navbar" class="navbar-collapse">
      <div >
        <div class="navbar-item">
          <div class="buttons">
            <router-link to="/" class="button" id="home_button" style="font-size: 110%;">
              <img id="home-img" name="home" alt="Home" src="/assets/logo_tango_top.png" fetchpriority="high" />
              
            </router-link>
            <router-link to="/dancerChoice" class="button" id="pets_button" style="font-size: 110%;">
              <strong> {{ $t('dancers') }}</strong>
            </router-link>
            
            <router-link to="/places" class="button " id="takers_button" style="font-size: 110%;">
              <strong> {{ $t('tango_places') }}</strong>
            </router-link>
            
          </div>
        </div>
         
      </div>
      <div > 
            <div v-if="isProfileLoaded" class="element">
              
                <router-link to="/account" class="nav-link"><strong>{{ name }} 
                 <i class="fas fa-eye fa-1x blue-color"></i></strong></router-link>
                <!--<i class="fa fa-user fa-1x blue-color"></i>-->
                <span  v-if="isAuthenticated" @click="logout" >
                  <router-link to="/" class="nav-link"><strong> {{ $t('logout') }}</strong> </router-link>
                </span>              

            </div>

            <div v-if="!isAuthenticated && !authLoading" class="element">
              
                <router-link to="/login" class="nav-link">
                  <i class="fa fa-user fa-1x blue-color"></i>
                  {{ $t('login') }}</router-link
                >
              
          
            </div>
            <div v-if="!isAuthenticated && !authLoading" class="element">
                <span>
                <router-link to="/register" class="nav-link">
                  <i class="fa fa-user blue-color fa-1x"></i>{{ $t('signup') }}
                </router-link>
              </span>
            </div>  
            <div class="element-img" > 
                 
              <img id="logo-img" name="logo" alt="Logo" src="/assets/logo_tango_all_small.png"  fetchpriority="high"/>
            
            </div> 
      

      </div>
  
    </div>

  </nav>
</template>
<script>
import { mapGetters, mapState } from 'vuex'

import { AUTH_LOGOUT } from '/static/actions/auth'
import store from '@/store'
export default {
  name: 'NavbarPet',

  methods: {
    logout: function () {
      store.dispatch('resetState')
      this.$store.dispatch(AUTH_LOGOUT).then(() => this.$router.push('/login'))
    },
  },
  computed: {
    ...mapGetters(['getProfile', 'isAuthenticated', 'isProfileLoaded']),
    ...mapState({
      authLoading: (state) => state.auth.status === 'loading',
      name: (state) =>
        `${state.user.profile.account.name}`,
    }),
  },
  created() {

    //      console.log('this.$store.state.user.profile.account.name', this.$store.state.user.profile.account.name);
  },
}


</script>

<style scoped>
.button {

    background: grey;

}
.router-link-exact-active.button {
    focus: #007bff;
    background: #cc2936;
    color: #007bff !important;
  
}
.exact-active-link.button {
    
    focus: #007bff;
    background: #cc2936;
    color: #007bff !important;
  
}

</style>      
