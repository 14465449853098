import {
  AUTH_REQUEST,
  AUTH_REGISTER,
  AUTH_ERROR,
  AUTH_SUCCESS,
  AUTH_SUCCESS_RENEW,
  AUTH_LOGOUT,
} from '/static/actions/auth'
import { USER_REQUEST } from '/static/actions/user'

// import router from "@/router";
import axios from 'axios'
import i18n from '@/i18n'

const state = {
  token: localStorage.getItem('user-token') || '',
  status: '',
  hasLoadedOnce: false,
  message: '',
  
}

const getters = {
  isAuthenticated: (state) => !!state.token,
  authStatus: (state) => state.status,
  authMess: (state) => state.message,
  
}

const actions = {
  [AUTH_REQUEST]: async ({ commit, dispatch }, user) => {
    await new Promise((resolve, reject) => {
      try {
        axios.post('/api/auth', user).then((response) => {
          
          //console.log('response.message', response.data.message)
          if (response.data.message) {
            resolve(false)  
          }
          else if (response.data === undefined || '' === response.data) {

            commit(AUTH_ERROR, i18n.global.t('ask_admin_help'))
            resolve(false)
            // router.push("/login");
          } else {
            axios.defaults.headers.common['Authorization'] =
              response.headers.authorization
            commit(AUTH_SUCCESS, response)

            dispatch(USER_REQUEST, response)
            resolve(true)
            //
          }
        })

        // return resp;
      } catch (error) {

        commit(AUTH_ERROR, i18n.global.t('ask_admin_help'))
        localStorage.removeItem('user-token')
        reject(error)
        // reject(err);
      }
    })

  },

  [AUTH_REGISTER]: ({ commit, dispatch }, user) => {
    axios
      .post('/api/auth/register', user)
      .then((resp) => {
        if ( resp.data === 'Please use another photo') {   
            
            commit(AUTH_ERROR, i18n.global.t('use_another_photo'))
        }
        else if ( resp.data === 'Please ask admin help') { 
          
          commit(AUTH_ERROR, i18n.global.t('ask_admin_help'))

        } else if (resp.data.Errorregistering !== undefined) {
          if (resp.data.Errorregistering.includes('profile_pswd_idx')) {

            commit(AUTH_ERROR, i18n.global.t('password_taken'))
          }
          if (resp.data.Errorregistering.includes('profile_email_idx')) {

            commit(AUTH_ERROR, i18n.global.t('email_taken'))
          }
        } else {
          axios.defaults.headers.common['Authorization'] =
            resp.headers.authorization
          commit(AUTH_SUCCESS, resp)
          //console.log('resp.data', resp)
          dispatch(USER_REQUEST, resp)
          // router.push("/account");
        }
        // return resp;
      })
      .catch((err) => {
        console.log('resp', err)
        commit(AUTH_ERROR, i18n.global.t('ask_admin_help'))
        localStorage.removeItem('user-token')
        // reject(err);
      })
  },

  [AUTH_LOGOUT]: ({ commit }) => {
    return new Promise((resolve) => {
      commit(AUTH_LOGOUT)
      localStorage.removeItem('user-token')
      localStorage.removeItem('profile')
      delete axios.defaults.headers.common['Authorization']
      resolve()
    })
  },
}

const mutations = {
  [AUTH_REQUEST]: (state) => {
    state.status = 'loading'
  },
  [AUTH_SUCCESS]: (state, resp) => {
    state.status = 'success'

    localStorage.setItem('user-token', resp.headers.authorization)
    state.token = localStorage.getItem('user-token')

    state.hasLoadedOnce = true
    state.message = ''
  },
  [AUTH_SUCCESS_RENEW]: (state) => {
    state.status = 'success'
    // localStorage.setItem("user-token", payload);
    state.token = localStorage.getItem('user-token')

    state.hasLoadedOnce = true
    state.message = ''
  },
  [AUTH_ERROR]: (state, error) => {
    //state.status = error;
    state.token = ''
    state.message = error

  },
  [AUTH_LOGOUT]: (state) => {
    state.token = ''
    state.status = ''
    state.message = ''
  },
}

export default {
  state,
  getters,
  actions,
  mutations,

}
