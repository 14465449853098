<template>
  <div class="row">
    <div class="col">
      
       <p style="white-space: nowrap;"><strong style="color: red">{{ tango.name }}</strong></p>
      <img :src="getImgPhoto(tango)" />
      <p><strong>{{ $t('description') }}: </strong></p>{{ tango.description }}
    </div>
    <div class="col">
      <p><strong>{{ $t('location') }}</strong></p>
      <p><strong>{{ $t('country') }}: </strong>{{ tango.country }}</p>
      <p><strong>{{ $t('city') }}: </strong>{{ tango.city }}</p>
      <p><strong>{{ $t('street') }}: </strong>{{ tango.street }}</p>
      <p><strong>{{ $t('region') }}: </strong>{{ tango.region }}</p>
    </div>
  </div>

  <div class="row">      
    <div class="col">
      <p></p>    
      <p>
        <router-link :to="'/leavenote/' + tango.id" style="color: #485fc7;" 
          ><strong>{{ $t('leave_review_for') }} {{ tango.name }}</strong></router-link>
      
      </p>
      <p><span>
        <router-link :to="'/notes/' + tango.id" style="color: #485fc7;" 
          ><strong>{{ $t('see_reviews_for') }} {{ tango.name }}</strong></router-link
        > </span>
      </p>
    </div>
    
  </div>  
  <div>
        <button class="badge badge-warning mr-2" style="font-size: .87em;" @click="cancel">
          {{ $t('back') }}
        </button>
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  name: 'TangoPlace',
  computed: mapState({ tango: (state) => state.places.tango }),
  methods: {
    // ...mapActions(["addCartItem"]),
    getImgPhoto(obj) {
      return obj.photo
    },
    cancel() {

      this.$router.go(-1)
    },    
  },
  created() {
    let id = this.$route.params.id

    this.$store.dispatch('getTangoPlace', { id }).then(() => {
      //this.tango = this.$store.state.places.tango
    })       
  },
}
</script>
