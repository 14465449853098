<template>
  <div v-if="profile.account === undefined" class="col-md-12">
    <div class="card card-container">{{ $t('logged_in') }}</div>
  </div>



  <div v-else>
    <br />
    <p>{{ error }}</p>
  </div>
</template>

<script>
import { mapState } from 'vuex'


export default {
  name: 'Error',

  
  computed: {
    ...mapState({ profile: (state) => state.user.profile }),
    error() {

      return this.$store.state.places.message
    },
  },  
  data() {
    return {
      message: '',
    }
  },
}
</script>

<style>
.edit-form {
  max-width: 300px;
  margin: auto;
}
</style>
