import {
  USER_REQUEST,
  USER_ERROR,
  USER_SUCCESS,
  USER_SUCCESS_RENEW,
} from '/static/actions/user'
import router from '@/router'
import { AUTH_LOGOUT } from '/static/actions/auth'
import axios from 'axios'

const state = { status: '', profile: localStorage.getItem('profile') || {} }

const getters = {
  isProfileLoaded: (state) => {

    if (Object.keys(state.profile).length !== 0) {
      return !!state.profile.account.name
    }
    return false
  },
  isLeader: (state) => {
    if (Object.keys(state.profile).length !== 0) {

      return state.profile.account.role === 1
    }
  },
  isFollower: (state) => {
    if (Object.keys(state.profile).length !== 0) {

      return state.profile.account.role === 2
    }
  },  
  isAdmin: (state) => {
    if (Object.keys(state.profile).length !== 0) {

      return state.profile.account.role === 4
    }
  },  
  isBoth: (state) => {
    if (Object.keys(state.profile).length !== 0) {
      return state.profile.account.role === 3
    }
  },
  dancerRole() {
    return state.profile.account.role
  },       

}

const actions = {
  [USER_REQUEST]: async ({ commit, dispatch, rootState }, user) => {
    let authToken = rootState.auth.token
    await axios
      .get('/api/user/' + JSON.stringify(user.data.id), {
        headers: { Authorization: authToken },
      })
      .then((resp) => {
        commit(USER_SUCCESS, resp.data)
        router.push('/account')
      })
      .catch((err) => {
        commit(USER_ERROR, err)
        dispatch(AUTH_LOGOUT)
      })
  },
 

}

const mutations = {
  [USER_REQUEST]: (state) => {
    state.status = 'loading'
  },
  [USER_SUCCESS]: (state, resp) => {
    state.status = 'success'
    localStorage.setItem('profile', JSON.stringify(resp.profile))
    //state.profile = JSON.parse(JSON.stringify(resp));
    state.profile = JSON.parse(localStorage.getItem('profile'))

  },
  [USER_SUCCESS_RENEW]: (state) => {
    state.status = 'success'
    state.profile = JSON.parse(localStorage.getItem('profile'))

  },
  [USER_ERROR]: (state) => {
    state.status = 'error'
  },
  [AUTH_LOGOUT]: (state) => {
    state.profile = {}
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
