<template>
  <div>
    <div class="headline">
      <p v-tooltip="`${$t('add_request_tip')}`">
        
        <router-link :to="'/request/'" class="badge badge-warning" style="font-size: .87em;"
            >{{ $t('new_request') }}</router-link>
      </p>
      
      <div class="request-list">
        <div class="container is-fluid">
          <div
            v-if="Array.isArray(profile.requests) && profile.requests[0]"
            class="tile is-ancestor"
          >
            <div
              class="tile is-parent"
              v-for="request in profile.requests"
              :key="request._id">
            

              <div v-if="request && request.request_desc">
                <div><strong>Description: {{ request.request_desc }}</strong></div>
                <div><strong>Month: {{ request.selectedFrom}}</strong></div>
                <div><strong>Location: {{ request.location.country}} {{ request.location.region ? ', Region:' + request.location.region :''}}  {{ request.location.city ? ', City: ' + request.location.city :''}}</strong></div>
                <div><strong>Years of tango: {{ request.yoe }}</strong></div>
                <div><strong>Dancer role: {{ role(request.dancerChoice) }}</strong></div>
                                
                <div>
                  <span @click="deleteRequest(request)"
                    ><router-link to="/account" class="badge badge-info"
                      >{{ $t('delete') }}</router-link
                    ></span
                  >
                </div>
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </div>

    
    <p></p>
      <p v-tooltip="`${$t('add_tangoplace_tip')}`">
        <router-link :to="'/place/'" class="badge badge-warning" style="font-size: .87em;"
            >{{ $t('new_place') }}</router-link>
      </p>
      
  

    <div v-if="profile.account.name">
    <p></p>
      <div>
        <img :src="getImgPhoto(profile.account)" />
      </div>
      <p>
        <router-link :to="'/accounts/' + profile.id" class="badge badge-info"
          >{{ $t('edit_my_profile') }}</router-link
        >
      </p>
      <p v-tooltip="`${$t('delete_profile_tip')}`">
        <span @click="deleteAccount(profile.id)"
          ><router-link to="/login" class="badge badge-info"
            >{{ $t('delete_my_profile') }}</router-link
          ></span
        >
      </p>

    </div>
    
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { REQUEST_DELETE } from '/static/actions/request'
import { ACCOUNT_DELETE } from '/static/actions/user'


import { defineRule } from 'vee-validate'
import { required, min} from '@vee-validate/rules';
import { directive } from 'vue-tippy'

defineRule('required', required);
defineRule('min', min);


export default {
  name: 'account',
  
  directives: {
    tippy: directive,
  },

  data() {
    return {
      showForm: false,
      message: '',
      currentPet: {},
      
      title: '',
      vacation_bid_price: 0,
      to: '',
      from: '',
      petid: '',
    }
  },
  computed: {
    ...mapGetters(['isBoth', 'isAdmin']),
    ...mapState({
      profile: (state) => state.user.profile
    }),
    
  },
  created() {
    this.message = ''
    //console.log('created getError()', this.getError())
  },
  methods: {
    ...mapActions(['formatDate']),
    role(choice) {

      if (choice) {
        return (choice == 2) ? "Follower" : (choice == 1) ? "Leader" : "Both"
      } 
      else ''
    },  
    getError() {
      //console.log('getError()', this.$store.state.profiles.message)
      return this.$store.state.profiles.message
    },
    formatDate(date) {
      return new Intl.DateTimeFormat('en-US', { dateStyle: 'long' }).format(
        new Date(date)
      )
    },
    cancelUpdate(curPet) {
      this.message = '',
      this.$store.commit('setMessage', this.message)
      this.showForm = false
      
      curPet.need.title = this.title
      curPet.need.from_date = this.from
      curPet.need.to_date = this.to
      curPet.need.vacation_bid_price = this.vacation_bid_price
      this.title = ''
      this.from = ''
      this.to = ''
      this.vacation_bid_price = ''  
      //console.log('cancel()', this.currentPet.title)
      //this.pets = this.$store.state.user.profile.pets
    },

    async deleteRequest(request) {
      //console.log('request.id', request.id)
      await this.$store
        .dispatch(REQUEST_DELETE, request)
        .then(() => {})
        .catch(function (error) {
          console.log(error)
        })
    },

    async deleteAccount(id) {
    //console.log('profile.id', profile.id)
    await this.$store
      .dispatch(ACCOUNT_DELETE, id)
      .then(() => {})
      .catch(function (error) {
        console.log(error)
      })
  },  

    deleteNeed(pet) {
      console.log('pet.need', pet.need)
      
    },
    editNeed(pet) {
      console.log('editNeed')
      this.message = ''
      this.currentPet = pet
       
      this.currentPet.need.from_date = new Date(pet.need.from_date)
      this.currentPet.need.to_date = new Date(pet.need.to_date)

      this.title = pet.need.title
      this.from = this.currentPet.need.from_date
      this.to = this.currentPet.need.to_date
      this.vacation_bid_price = pet.need.vacation_bid_price

      console.log('edit this.title', pet.need.title, this.title)
      

      this.showForm = true

            //pet.need.need_id = undefined
      //console.log('this.profile.pets', this.profile.pets)
    },
    newNeed(pet) {
      this.currentPet = pet
      this.currentPet.need = {
        owner_id: pet.owner_id,
        title: '',
        from_date: '',
        to_date: '',
        vacation_bid_price: 0,
      }
      this.$store.commit('setMessage', '')
      this.showForm = true
       
    },
    checkDates(from_date, to_date) {
      //console.log('checkDates from_date to_date', from_date, to_date)
      if (!from_date || from_date === "NaN-NaN-NaN") {
        //console.log('error dates', 'Empty dates') ;
        return false
      }
      if (!to_date || to_date === "NaN-NaN-NaN") {
        //console.log('error dates', 'Empty dates') ;
        return false
      }
      if (new Date(from_date).getTime() <= new Date().getTime()) {
        //console.log('error dates', 'From date is less than today') ;
        return false
      }
      if (new Date(from_date).getTime() == new Date(to_date).getTime()) {
        //console.log('Wrong dates');
        return false
      }
      if (new Date(from_date).getTime() > new Date(to_date).getTime()) {
        //console.log('Wrong dates');
        return false
      }
      return true
    },
    async addNeed(pet) {
      //console.log('addNeed title', pet.need.title)
      if (!pet.need.title) {
        //console.log('addNeed ', pet.need.title)
        this.message = this.$t('description_need_req')
        //this.$store.commit('setMessage', this.message)
        return false
      }
      if (!this.checkDates(pet.need.from_date, pet.need.to_date)) {
        //console.log('addNeed checkDates')
        this.message = this.$t('wrong_dates')
        this.showForm = true
        return false
      }
      
      this.message = ''
      //console.log('addNeed Promise from', pet.need.from_date)

       
    },
    getNeed() {
      //console.log('pet.need', pet.need)
    },
    getImgPhoto(obj) {
      return obj.photo
    },
  },
}
</script>
<style>
.data-v-tooltip {
  
  --v-tooltip-left: 50%;
} 
.blue-color {
  color: blue;
}
.green-color {
  color: green;
}

.red-color {
  color: grey;
}
</style>
