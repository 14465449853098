<template>
  <div v-if="profile" class="col-md-12">
    <div class="card card-container">
    <p><strong>{{ $t('edit_your_profile') }}</strong></p>
      <Form @submit="onSubmit" ref="proForm">
        <div>

          <div class="form-group">
            <label for="fullname"><strong>{{ $t('full_name') }}</strong>
              <i class="fa fa-asterisk fa red-color"></i>
            </label>
            <Field :validateOnChange="true" :validateOnInput="true"
              name="name"
              type="text" 
              class="form-control"
              v-model="profile.account.name" rules="required|min:2"
            />
            <ErrorMessage name="name" class="error-feedback" />
          </div>

          <div class="form-group">
            <span name="body_type"  v-tooltip="`${$t('body_type_tip')}`"><strong>{{ $t('body_type') }}</strong>
              <i class="fa fa-asterisk fa red-color"></i>
            </span>
            <Field :validateOnChange="true" :validateOnInput="true"
              name="body_type"
              type="text"
              class="form-control"
              v-model="profile.account.body_type" rules="required"
            />
            <ErrorMessage name="body_type" class="error-feedback" />
          </div>

          <div class="form-group">
            <span name="yoe"  v-tooltip="`${$t('yoe_tip')}`"><strong>{{ $t('yoe') }}</strong>
             <i class="fa fa-asterisk fa red-color"></i>
            </span> 
            <Field :validateOnChange="true" :validateOnInput="true"
              name="yoe"
              type="number"
              class="form-control"
              v-model="profile.account.yoe" rules="required|max:2"
            />
            <ErrorMessage name="yoe" class="error-feedback" />
          </div>

          <div class="form-group">
            <span name="height" v-tooltip="`${$t('height_tip')}`" ><strong>{{ $t('height') }}</strong>
             <i class="fa fa-asterisk fa red-color"></i>
            </span> 
            <Field :validateOnChange="true" :validateOnInput="true"
              name="height"
              type="number"
              class="form-control"
              v-model="profile.account.height" rules="required|max:3"
            />
            <ErrorMessage name="height" class="error-feedback" />
          </div>          


          <div class="form-group">
            <label for="email"><strong>{{ $t('email') }}</strong>
              <i class="fa fa-asterisk fa red-color"></i>
            </label>
            <Field :validateOnChange="true" :validateOnInput="true"
              name="email"
              type="email"
              class="form-control"
              v-model="profile.account.email" rules="required|email"
            />
            <ErrorMessage name="email" class="error-feedback" />
          </div>

          <div class="form-group">
            <span name="phone"  v-tooltip="`${$t('ph_tip')}`"><strong>{{ $t('phone') }}</strong>
             
            </span> 
            <Field :validateOnChange="true" :validateOnInput="true"
              name="phone"
              type="text"
              class="form-control"
              v-model="profile.account.phone" rules="max:10|min:5"
            />
            <ErrorMessage name="phone" class="error-feedback" />
          </div>

          <div class="form-group">
            <label for="country"><strong>{{ $t('country') }}</strong>
              <i class="fa fa-asterisk fa red-color"></i>
            </label>
            <Field
              name="country"
              as="select"
              class="form-control"
              @change="updateCountry"
              v-model="profile.account.country" rules="required"
            >
              <option v-for="country in countries" :value="country" :key="country">
                {{ $t(country) }}
              </option>
              
            </Field>
            <ErrorMessage name="country" class="error-feedback" />
          </div>
          <div class="form-group">
            <label for="city"><strong>{{ $t('city') }}</strong>
              <i class="fa fa-asterisk fa red-color"></i>
            </label>
            <Field
              name="city"
              as="select"
              class="form-control"
              v-model="profile.account.city"
              @change="updateCity" rules="required"
            >
              <option v-for="city in cities" :value="city.value" :key="city.value">
                {{ city.translation }}
              </option>
            </Field>
            <ErrorMessage name="city" class="error-feedback" />
          </div>

          <div class="form-group">
            <label for="region"><strong>{{ $t('region') }}</strong>
              <i class="fa fa-asterisk fa red-color"></i>
            </label>
            <Field :validateOnChange="true" :validateOnInput="true"
              name="region"
              type="text"
              class="form-control"
              v-model="profile.account.region" rules="required|min:3"
            />
            <ErrorMessage name="region" class="error-feedback" />
          </div>          

          <div class="form-group">
            <label for="interests"><strong>{{ $t('your_interests') }}</strong>
              <i class="fa fa-asterisk fa red-color"></i>
            </label>
            <Field :validateOnChange="true" :validateOnInput="true"
              name="interests"
              type="text"
              class="form-control"
              v-model="profile.account.interests" rules="required|min:3"
            />
            <ErrorMessage name="interests" class="error-feedback" />
          </div>

          <div class="form-group">
            <span name="photo"  v-tooltip="`${$t('profile_photo_tip')}`"><strong>{{ $t('photo') }}</strong></span> 
            <photo-upload ref="uploadPhotos" @changeMessage="changeFromChild" />
          </div>
          
            
        
          <p v-tooltip="`${$t('profile_availability_tip')}`">
            <label class="checkbox-control" >
              <input
                type="checkbox" :checked="profile.account.available"
                v-model="profile.account.available" 
              /><strong>Available</strong>
              <div class="checkbox-box"></div>
            </label>
          </p>
            
           

          <div class="form-group">
            
            <button @click="cancelUpdate" class="badge badge-warning  mr-2" style="font-size: .87em;">
              {{ $t('cancel') }}
            </button>
            <button type="submit" class="badge badge-info" style="font-size: .87em;">{{ $t('update') }}</button>
            

            <div
              v-if="getError().length > 0"
              class="alert alert-danger"
              role="alert"
            >
              {{ getError() }}
            </div>
          </div>
        </div>
      </Form>

    </div>
  </div>

  <div v-else>
    <br />
    <p></p>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { USER_SUCCESS_RENEW } from '/static/actions/user'
import { directive } from 'vue-tippy'
import { required, confirmed, min, email, max} from '@vee-validate/rules';


import { Form, Field, ErrorMessage, defineRule } from 'vee-validate'
defineRule('required', required);
defineRule('confirmed', confirmed);
defineRule('min', min);
defineRule('max', max);
defineRule('email', email);
import PhotoUpload from '../core/PhotoUpload'

export default {
  name: 'Profile',

  components: {
    Form,
    Field,
    ErrorMessage,
    PhotoUpload,
  },
  directives: {
    tippy: directive,
  },
  computed: {
    ...mapState({ profile: (state) => state.user.profile }),
    countries() {
      return ['UKRAINE', 'SWITZERLAND', 'GERMANY', 'SPAIN']
    },
    cities() {
      return this.translateCities(this.$store.state.product.cities)
    },
    writeMessage: {
      // getter
      get() {

        return this.message
      },
      //setter
      set(newValue) {
        this.message = newValue

      },
    },
  },
  data() {
    return {
      id: 0,
      file: null,
      photos: [],

      message: '',
    }
  },
  methods: {
    ...mapActions(['cancelUpdate']),
    translateCities(cities) {
      let filledCities= [];
      for(let i=0; i<cities.length;i++) { 
        //console.log('translateCities', this.$t(cities[i]))
        filledCities[i] = {'translation':this.$t(cities[i]), 'value':cities[i]};
      } 

      return filledCities
    },
    changeFromChild(event) {
      this.message = event

      this.$store.commit('ERROR', event)
    },
    getError() {
      return this.$store.state.profiles.message
    },
    updateCountry($event) {

      this.$store.commit('setSelectedCountry', $event.target.value)
      this.profile.account.country = $event.target.value

      this.$refs.proForm.setFieldValue('city', '')
      this.$store.dispatch('getCities').then(() => {

      })
    },
    updateCity($event) {
      // this.$store.commit('setSelectedCity', $event.target.value)
      this.profile.account.city = $event.target.value

    },
    async onSubmit() {
      let photos = []

      if (this.getError().length > 0 ) {
        return false
      }
            
      if (
        this.$refs.uploadPhotos.photos &&
        this.$refs.uploadPhotos.photos.length > 0
      ) {
        photos = photos.concat(this.$refs.uploadPhotos.photos)

        this.profile.filedata = photos[0].data
        this.profile.filename = photos[0].name
      }

      await this.$store
        .dispatch('updateProfile', this.profile)

        .then(() => {

          // this.message = this.$store.state.profiles.message
        })
        .catch(function (error) {
          console.log(error)
        })
    },
  },
  mounted() {
    // this.getProfile(this.$route.params.id);
    
    this.$store.commit(USER_SUCCESS_RENEW, localStorage.getItem('profile'))
    
    
    this.$store.commit('setSelectedCountry', this.profile.account.country)

    this.$store.dispatch('getCities', this.profile.account.country).then(() => {

    })
  },
}
</script>

<style>
.edit-form {
  max-width: 300px;
  margin: auto;
}
</style>
