<template>
  <div class="col-md-12">
    <div class="card card-container">
      <img
        id="profile-img"
        alt="Avatar"
        src="/assets/avatar_2x.png"
        class="profile-img-card"
      />

      <Form @submit="login" ref="logForm">
        <div class="form-group">
          <label for="email">{{ $t('email') }}</label>
          <Field name="email" type="text" class="form-control" rules="required|email" :validateOnChange="true" />
          <ErrorMessage name="email" class="error-feedback" />
        </div>
        <div class="form-group">
          <label for="password">{{ $t('password') }}</label>
          <Field name="password" type="password" class="form-control" rules="required" :validateOnChange="true" :validateOnInput="true"/>
          <ErrorMessage name="password" class="error-feedback" />
        </div>

        <div class="form-group">
          <button class="btn btn-info btn-block" :disabled="loading">
            <span
              v-show="loading"
              class="spinner-border spinner-border-sm"
            ></span>
            <span>{{ $t('login') }}</span>
          </button>
        </div>

        <p class="forgot-password text-right mt-2 mb-4">
          <router-link to="/forgot-password">{{ $t('forgot_password') }}</router-link>
        </p>
        <p class="forgot-password text-right mt-2 mb-4">
          <router-link to="/change-password">{{ $t('change_password') }}</router-link>
        </p>

        <div class="form-group">
          <div v-if="message" class="alert alert-danger" role="alert">
            {{ message }}
          </div>
        </div>
      </Form>
    </div>
  </div>
</template>

<style>
.login {
  display: flex;
  flex-direction: column;
}

label {
  display: block;
  margin-top: 10px;
}
.card-container.card {
  max-width: 350px !important;
  padding: 10px 20px;
}
.card {
  
  padding: 7px 2px 30px;
  margin: 0 auto 25px;
  margin-top: 0px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}
.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
.error-feedback {
  color: yellow;
  font-size: 17px;
}
</style>

<script>
import { mapGetters } from 'vuex'
import { AUTH_REQUEST } from '/static/actions/auth'
import { directive } from 'vue-tippy'
import { required, confirmed, min, email, max} from '@vee-validate/rules';


import { Form, Field, ErrorMessage, defineRule } from 'vee-validate'
defineRule('required', required);
defineRule('confirmed', confirmed);
defineRule('min', min);
defineRule('max', max);
defineRule('email', email);

import i18n from '@/i18n'

export default {
  name: 'Login',
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  directives: {
    tippy: directive,
  },  
  data() {
    return {
      loading: false,
      successful: false,
      message: '',
     
    }
  },
  computed: {
    ...mapGetters(['isAuthenticated']),
  },
  created() {
    
    if (this.isAuthenticated) {
      this.$router.push('/profile')
    }
  },
  methods: {
    // const log = login: function() {
    async login(user) {
      const pr = await this.$store.dispatch(AUTH_REQUEST, user)
                  if (!pr) {
                this.message = i18n.global.t('error_with_typed_credentials')
              } else {
        this.loading = true
      }
    },
  },
}
</script>
