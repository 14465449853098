import axios from 'axios'
import router from '@/router'
import { PLACE_DELETE } from '/static/actions/place'
//import i18n from '@/i18n'

const getDefaultState = () => {
  return {
    places: [],
    notes: [],
    tango: {},
    tangoCountry: '',
    tangoCity: '',
    selectedStreet: '',
    tangoRegion: '',
    message: '',
  }
}

const state = getDefaultState()

const mutations = {
  resetPlacesState (state) {
    Object.assign(state, getDefaultState())
  },   
  UPDATE_TANGO_NOTES(state, payload) {
    state.notes = payload
    state.message = ''
  },
  UPDATE_TANGO(state, payload) {

    state.tango = payload
    
  },
  setMessage(state, event) {
    state.message = event
  },
  setTangoCountry(state, event) {
    state.tangoCountry = event
  },
  setTangoCity(state, event) {

    state.tangoCity = event
  },
  setTangoRegion(state, event) {

    state.tangoRegion = event 
  },  
  UPDATE_PLACES(state, payload) {
    state.places = payload
  },
  ERROR(state, payload) {

    state.message = payload
  },  
}

const actions = {
  async getTangoPlace({ commit }, id) {

    await axios
      .get('/api/places/tango/' + JSON.stringify(id))
      .then((response) => {
        let tangoPlace = response.data
        
        commit('UPDATE_TANGO', tangoPlace)
        
        //router.push("/tangoPlace/");
      })
      
  },

  async [PLACE_DELETE] ({ commit }, curPlace) {
    const postdata = { place_id: curPlace.place_id, owner_id: curPlace.owner_id, user_id: curPlace.userid }

    await new Promise((resolve) => {  
      axios.post('/api/delete/place/', postdata).then((response) => {
        
        if (response.data !== 'ok') {
          commit('ERROR', 'Can not delete tango place')
          router.push('/error')
        }
        else {
          resolve(true)
          router.push('/places')
        }  
      })
    })
 
  },    
  async getNotes({ commit }, id) {

    await axios.get('/api/notes/' + JSON.stringify(id)).then((response) => {
      commit('UPDATE_TANGO_NOTES', response.data)

    })
  },
  async getTaker({ commit, dispatch }, id) {

    //const runResult = await new Promise((resolve, reject) => {
    try {
      axios.get('/api/taker/' + JSON.stringify(id)).then((response) => {

        let taker = response.data.account
        taker.id = response.data.id
        commit('UPDATE_TAKER', taker)

        dispatch('getNotes', taker.id)
      })
    } catch (error) {
      console.log('getTaker error', error)
      //reject(error);
    }
    //})
    //return runResult
  },
  async getPlaces({ commit }) {
    let location = {
      city: state.tangoCity,
      country: state.tangoCountry,
      region: state.tangoRegion,
      street: state.selectedStreet,
      name: '',
    }

    await axios
      .get(`/api/places/` + JSON.stringify(location))
      .then((response) => {

        commit('UPDATE_PLACES', response.data)
        
      })
  },
  saveNote({ rootState , commit}, note) {
    let owner_id = rootState.user.profile.id
    note.owner_id = owner_id
    note.note = note.note.replace(/\\n/g, ' ')
    note.owner_name = rootState.user.profile.account.name
    

    axios.post(`/api/savenote/` + encodeURIComponent(JSON.stringify(note))).then((response) => {

      commit('UPDATE_TANGO_NOTES', response.data)
    })
    //router.push('/taker/'+ JSON.stringify(note.taker_id));
    router.push('/places/')
  },
  leaveNote(id) {

    router.push('/leavenote/' + JSON.stringify(id))
  },
  async search({ commit }, placesSearch) {

    await new Promise((resolve, reject) => {
      try {
        axios
          .get(`/api/search/places/` + JSON.stringify(placesSearch))
          .then((response) => {

            commit('UPDATE_PLACES', response.data)
            resolve(true)
          })
      } catch (error) {
        reject(error)
      }
    })

  },
}

const getters = {
  places: (state) => state.places,
  notes: (state) => state.notes,
  tango: (state) => state.tango,
  tangoCity: (state) => state.tangoCity,
  tangoCountry: (state) => state.tangoCountry,
  tangoRegione: (state) => state.tangoRegione,
}

const placesModule = {
  state,
  mutations,
  actions,
  getters,
}

export default placesModule
