import { createStore } from 'vuex'
import product from './modules/product'
import places from './modules/places'
import profiles from './modules/profiles'
import user from './modules/user'
import auth from './modules/auth'
import i18n from '../i18n'
import axios from 'axios'

import { USER_SUCCESS_RENEW } from '/static/actions/user'

import { AUTH_SUCCESS_RENEW, AUTH_LOGOUT } from '/static/actions/auth'


export default createStore({
  state: {
    token: localStorage.getItem('user-token') || {},
    profile: localStorage.getItem('profile') || {},
    chosenLocale: 'en',
  },

  mutations: {
    //initialiseStore() {
      // console.log("token gl", JSON.parse(JSON.stringify(localStorage.getItem('user-token'))));
      // console.log("profile gl:", JSON.parse(JSON.stringify(localStorage.getItem('profile'))));
      // if (localStorage.getItem('user-token')) {
      //   // state.agreedToPrivacy = true;
      //   this.commit(AUTH_SUCCESS, localStorage.getItem('user-token'));
      // }
      // if (localStorage.getItem('profile')) {
      //   // state.agreedToPrivacy = true;
      //   this.commit(USER_SUCCESS, localStorage.getItem('user-token'));
      // }
    //},
    
    setChosenLocale(state, payload) {

        state.chosenLocale = payload
    },
  },
  actions: {
    resetState ({ commit }) {
      let state = this.state;
      let newState = {};

      Object.keys(state).forEach(key => {
        newState[key] = {}; // or = initialState[key]

      });

      state = newState
      commit('resetProductState', this.state.product)
      commit('resetPlacesState', this.state.places)

      //console.log("this.state", this.state)
      
    },  
    initialiseStore(){
      //this.state.product = {}
      //this.state.places = {}
      //console.log('state.product  ', this.state.product )
      
      //console.log('state.places', this.state.places)
      console.log(
        'token act gl',
        //JSON.parse(JSON.stringify(localStorage.getItem('user-token')))
      )
      console.log(
        'profile act gl:',
        //JSON.parse(JSON.stringify(localStorage.getItem('profile')))
      )

      //this.dispatch(AUTH_LOGOUT);
      if (
        'undefined' ===
        JSON.parse(JSON.stringify(localStorage.getItem('profile')))
      ) {
        this.dispatch(AUTH_LOGOUT)
      }
      if (
        null === JSON.parse(JSON.stringify(localStorage.getItem('profile')))
      ) {
        this.dispatch(AUTH_LOGOUT)
      } else {
        if (localStorage.getItem('user-token')) {
          // state.agreedToPrivacy = true;
          this.commit(AUTH_SUCCESS_RENEW, localStorage.getItem('user-token'))
        }
        if (localStorage.getItem('profile')) {
          // state.agreedToPrivacy = true;
          this.commit(USER_SUCCESS_RENEW, localStorage.getItem('profile'))
        }
      }
    },
    
    formatDate(date) {
      return new Intl.DateTimeFormat('en-US', { dateStyle: 'long' }).format(
        new Date(date)
      )
    },
// JSON.stringify(i18n.locale)
    async getLang() {
      console.log('this.state.chosenLocale', this.state.chosenLocale)
      await axios
      .get('/api/lang/' + JSON.stringify(this.state.chosenLocale))
      .then((resp) => {
        console.log('lng', resp.data)

      })
    },
    
  },
  modules: {
    product,
    places,
    profiles,
    auth,
    user,
    i18n,

  },
})


