<template>
  <div class="row">
    <div class="col">
    
      <p ><strong style="color: red">{{ dancer.account.name}} </strong></p >
      <img :src="getImgPhoto(dancer.account)" style="padding-left: 3px" />
    </div>
  </div>
  <div class="row">     
    <div class="col">
      <strong style="font-size:110%;">{{ $t('role') }}:</strong> {{ role(dancerRole) }}<br>
      <strong style="font-size:110%;">{{ $t('city') }}:</strong> {{ dancer.account.city }}<br>
      <strong style="font-size:110%;">{{ $t('email') }}:</strong> {{ dancer.account.email }}<br>
      <strong style="font-size:110%;">{{ $t('phone') }}:</strong> {{ dancer.account.phone }} <br>
      <strong style="font-size:110%;">{{ $t('yoe') }}:</strong> {{ dancer.account.yoe }}<br>
      <strong style="font-size:110%;">{{ $t('height') }}:</strong> {{ dancer.account.height }}<br>
      <strong style="font-size:110%;">{{ $t('body_type') }}:</strong> {{ dancer.account.body_type }}<br>
      <strong style="font-size:110%;">{{ $t('your_interests') }}:</strong> {{ dancer.account.interests }}      
    </div>

      <div class="col">
        <strong style="font-size:110%;">{{ $t('requests_for') }}:</strong> <br>
            <div
              class="tile is-parent"
              v-for="request in dancer.requests"
              :key="request._id">
            

              
                <div><strong>Description: </strong>{{ request.request_desc }}</div>
                <div><strong>Month: </strong>{{ request.selectedFrom}}</div>
                <div><strong>Location: </strong>{{ request.location.country}} {{ request.location.region ? ', Region:' + request.location.region :''}}  {{ request.location.city ? ', City: ' + request.location.city :''}}</div>
                <div><strong>Years of tango:</strong> {{ request.yoe }}</div>
                <div><strong>Dancer role:</strong> {{ role(request.dancerChoice) }}</div>
            </div>
    </div>  
    
  </div>

  <div>
        <button class="badge badge-warning mr-2" style="font-size: .87em;" @click="cancel">
          {{ $t('back') }}
        </button>
  </div>  
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: 'DancerProfile',
  data() {
    return {
      dancer: {account:{}, requests:[]},

    }
  },
  computed: {
    ...mapGetters(['dancerRole', 'isBoth', 'isAdmin']),

  },   
  methods: {
    getImgPhoto(obj) {
      return obj.photo
    },
    cancel() {
      this.$router.go(-1)
    }, 
    role(choice) {
      if (choice) {
        return (choice == 2) ? "Follower" : (choice == 1) ? "Leader" : "Both"
      } 
      else ''
    },        
  },
  created() {
    let id = this.$route.params.id
    
    this.$store.dispatch('getDancer', { id }).then(() => {
      // console.log('dancer state', this.$store.state.product.dancer)
      this.dancer = this.$store.state.product.dancer
    })      
  },
}
</script>
