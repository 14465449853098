<template>
  <div class="col-md-12">
    <div class="card card-container">
      <img
        id="profile-img"
        alt="Avatar"
        src="/assets/avatar_2x.png"
        class="profile-img-card" 
      />
      <Form @submit="handleRegister" ref="regForm">
        <div v-if="!successful">
        <span name="photo"  v-tooltip="`${$t('profile_photo_tip')}`"><strong>{{ $t('photo') }}</strong>
          <i class="fa fa-asterisk fa red-color"></i>
        </span>        
          <photo-upload name="photo"
            ref="uploadPhotos"
            @changeMessage="changeFromChild($event)"
          />
          <span role="alert" class="error-feedback" name="photo_error"
            >{{ this.message }}
          </span>
        
          <div class="form-group">
            <label for="fullname"><strong>{{ $t('full_name') }}</strong>
              <i class="fa fa-asterisk fa red-color"></i>
            </label>
            <Field name="fullname" type="text" class="form-control" rules="required|min:2" :validateOnInput="true" :validateOnChange="true" />
            <ErrorMessage name="fullname" class="error-feedback" />
          </div>
          <div class="form-group">
            <label name="height"  v-tooltip="`${$t('height_tip')}`"><strong>{{ $t('height') }}</strong>
              <i class="fa fa-asterisk fa red-color"></i>
            </label>   
            <Field name="height" type="number" class="form-control" rules="required|max:3" :validateOnInput="true" :validateOnChange="true" />
            <ErrorMessage name="height" class="error-feedback" />
          </div> 
          <div class="form-group">
         <label name="body_type"  v-tooltip="`${$t('body_type_tip')}`"><strong>{{ $t('body_type') }}</strong>
            <i class="fa fa-asterisk fa red-color"></i>
          </label>
            <Field name="body_type" type="text" class="form-control" rules="required|min:3" :validateOnInput="true" :validateOnChange="true" />
            <ErrorMessage name="body_type" class="error-feedback" />
          </div>   
          <div class="form-group">
         <label name="yoe"  v-tooltip="`${$t('yoe_tip')}`"><strong>{{ $t('yoe') }}</strong>
            <i class="fa fa-asterisk fa red-color"></i>
          </label>
            <Field name="yoe" type="number" class="form-control" rules="required|max:2" :validateOnInput="true" :validateOnChange="true" />
            <ErrorMessage name="yoe" class="error-feedback" />
          </div>                          
          <div class="form-group">
            <label for="email"><strong>{{ $t('email') }}</strong>
              <i class="fa fa-asterisk fa red-color"></i>
            </label>
            <Field name="email" type="email" class="form-control" rules="required|email" :validateOnInput="true" :validateOnChange="true" />
            <ErrorMessage name="email" class="error-feedback" />
          </div>
          <div class="form-group">
            <label name="phone"  v-tooltip="`${$t('ph_tip')}`"><strong>{{ $t('phone') }}</strong></label> 
            <Field name="phone" type="number" class="form-control" rules="max:10|min:5" :validateOnInput="true" :validateOnChange="true" />
            <ErrorMessage name="phone" class="error-feedback" />
          </div>
          <div class="form-group">
            <label for="country"><strong>{{ $t('country') }}</strong>
              <i class="fa fa-asterisk fa red-color"></i>
            </label>
            <Field
              name="country"
              as="select"
              class="form-control"
              @change="updateCountry" rules="required"
            >
              <option value="" disabled>{{ $t('choose_country') }}</option>
              <option value="UKRAINE" @change="updateCountry">{{ $t('UKRAINE') }}</option>
              <option value="SWITZERLAND" @change="updateCountry">{{ $t('SWITZERLAND') }}</option>
              <option value="GERMANY" @change="updateCountry">{{ $t('GERMANY') }}</option>
              <option value="SPAIN" @change="updateCountry">{{ $t('SPAIN') }}</option>
            </Field>
            <ErrorMessage name="country" class="error-feedback" />
          </div>
          <div class="form-group">
            <label for="city"><strong>{{ $t('city') }}</strong>
              <i class="fa fa-asterisk fa red-color"></i>
            </label>
            <Field name="city" as="select" class="form-control" rules="required" @change="updateCity">
              <option
                v-for="city in cities"
                :value="city.value"
                :key="city.value"
              >
                {{ city.translation }}
              </option>
            </Field>
            <ErrorMessage name="city" class="error-feedback" />
          </div>

          <div class="form-group">
            <label for="region"><strong>{{ $t('region') }}</strong>
              <i class="fa fa-asterisk fa red-color"></i>
            </label>
            <Field name="region"  type="text" class="form-control" rules="required|min:3" :validateOnInput="true" :validateOnChange="true" />

            
            <ErrorMessage name="region" class="error-feedback" />
          </div>

          <div class="form-group">
            <label for="interests"><strong>{{ $t('your_interests') }}</strong>
              <i class="fa fa-asterisk fa red-color"></i>
            </label>
            <Field name="interests" type="text" class="form-control" rules="required|min:3" :validateOnInput="true" :validateOnChange="true" />
            <ErrorMessage name="interests" class="error-feedback" />
          </div>

          <div class="form-group">
            <label name="password"  v-tooltip="`${$t('pswd_tip')}`"><strong>{{ $t('password') }}</strong>
              <i class="fa fa-asterisk fa red-color"></i>
            </label> 
            <Field name="password" type="password" class="form-control" rules="required|min:6" ref="password" vid="password" :validateOnInput="true" :validateOnChange="true" />
            <ErrorMessage name="password" class="error-feedback"/>
          </div>
          <div class="form-group">
          <label name="password_confirmation" ><strong>{{ $t('confirm_password') }}</strong>
            <i class="fa fa-asterisk fa red-color"></i>
          </label>
            
            <Field
              name="password_confirmation"
              type="password" 
              class="form-control" rules="required|confirmed:@password" :validateOnChange="true" :validateOnInput="true" ref="password_confirmation"
            />
            <ErrorMessage name="password_confirmation" class="error-feedback" />
          </div>

          <div class="form-group">
            <span name="role" v-tooltip="`${$t('lead_follower_tip')}`"><strong>{{ $t('lead_follower') }}</strong>
              <i class="fa fa-asterisk fa red-color"></i>
            </span> 
            <br>
            <Field name="role" type="radio" :value="1"  rules="required" :validateOnChange="true" :validateOnInput="true" />
            <span class="radio-label" style="margin: 10px" ><strong>{{ $t('lead') }}</strong></span>

            <Field name="role" type="radio" :value="2" rules="required" :validateOnChange="true" :validateOnInput="true" />
            <span class="radio-label" style="margin: 10px"><strong>{{ $t('follower') }}</strong>
            </span>

            <Field name="role" type="radio" :value="3" rules="required" :validateOnChange="true" :validateOnInput="true" />
            <span v-tooltip="`${$t('both_tip')}`" class="radio-label" style="margin: 10px"><strong>{{ $t('both') }}</strong>
            </span>
            <ErrorMessage name="role" class="error-feedback" />
          </div>

          <div class="form-group">
            <button class="btn btn-info">{{ $t('sign_up') }}</button>
          </div>
          <div class="form-group">
            <div v-if="authMess" class="alert alert-danger" role="alert">
              {{ authMess }}
            </div>
          </div>
        </div>
      </Form>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { AUTH_REGISTER } from '/static/actions/auth'
import { directive } from 'vue-tippy'
import { required, confirmed, min, email, max} from '@vee-validate/rules';


import { Form, Field, ErrorMessage, defineRule } from 'vee-validate'
defineRule('required', required);
defineRule('confirmed', confirmed);
defineRule('min', min);
defineRule('max', max);
defineRule('email', email);

// import * as yup from 'yup'
import PhotoUpload from '../core/PhotoUpload'


export default {
  name: 'Register',
  components: {
    Form,
    Field,
    ErrorMessage,
    PhotoUpload,
  },
  directives: {
    tippy: directive,
  },
  data() {

    return {
      successful: false,
      loading: false,
      
      message: '',
       
    }
  },
  computed: {
    ...mapGetters(['isAuthenticated', 'authMess']),
    cities() {
      return this.translateCities(this.$store.state.product.cities)
    },
  },
  mounted() {

    if (this.isAuthenticated) {
      this.$router.push('/profile')
    }
  },
  created () {

    this.$store.commit('AUTH_ERROR', '')
  },
  methods: {
    changeFromChild($event) {
      this.message = $event
    },
    translateCities(cities) {
      let filledCities= [];
      for(let i=0; i<cities.length;i++) { 
        //console.log('translateCities', this.$t(cities[i]))
        filledCities[i] = {'translation':this.$t(cities[i]), 'value':cities[i]};
      } 

      return filledCities
    },
    updateCountry($event) {
      this.$store.commit('setSelectedCountry', $event.target.value)
      this.$refs.regForm.setFieldValue('city', '')
      this.$store.commit('setSelectedCity', '')
      this.$store.dispatch('getCities').then(() => {})
    },
    updateCity($event) {
      this.$store.commit('setSelectedCity', $event.target.value)
    },
    checkCities() {
      const cList = new Promise(() => {})
      return cList
    },
    async handleRegister(user) {
      // evt.preventDefault();
      this.successful = false
      new Promise((resolve) => {
        this.$store
          .dispatch('getCities')
          .then(() => {
            // commit('setUpdate', data);
            var cts = this.$store.state.product.cities
            if (cts.includes(this.$store.state.product.selectedCity)) {
              resolve()
            } else {
              this.message = "Cities don't match"
              return false
            }
          })
          .then(() => {
            let photos = []
            if (
              Array.isArray(this.$refs.uploadPhotos.photos) &&
              this.$refs.uploadPhotos.photos[0]
            ) {
              photos = photos.concat(this.$refs.uploadPhotos.photos)
              user.filedata = photos[0].data
              user.filename = photos[0].name
              this.$store.dispatch(AUTH_REGISTER, user)

            } else {
              this.message += this.$t('profile_photo')
              
            }
          })
          .catch((error) => {
            // commit('setError', error);
            console.log('Invalid request ' + error)
          })
      })
    },
  },
}

</script>

<style scoped>
label {
  display: block;
  margin-top: 10px;
}
.card-container.card {
  max-width: 350px !important;
  padding: 10px 20px;
}
.card {
  background-color: #f7f7f7;
  padding: 5px 7px 5px;
  margin: 0 auto 25px;
  margin-top: 0px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}
.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
.error-feedback {
  color: yellow;
  font-size: 17px;
}
</style>
