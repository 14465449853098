<template>
  <div class="card card-container">
  <p><strong>{{ $t('add_your_request') }}</strong></p>
    <div v-if="!submitted" class="col-md-12">
      <Form
        @submit="addRequest"
        :initial-values="request"
        ref="requestForm"
      >
        <div v-if="!successful">
          <div class="form-group">
            <label for="request_desc"><strong>{{ $t('request_desc') }}</strong>
              <i class="fa fa-asterisk fa red-color"></i>
            </label>

          <Field v-slot="{ field, errors }" v-model="request.request_desc" name="request_desc" rules="required">
            <textarea v-bind="field" name="request_desc" cols="30" rows="4"/>
            <div v-if="errors[0]" class="error-feedback">{{errors[0]}}</div>
          </Field>    
 
            
          </div>

          <div>
            <label for="dancerChoice"><strong>{{ $t('lead_follower_tip') }}</strong>
              <i class="fa fa-asterisk fa red-color"></i>
            </label>
          <p>
          <input
            id="one"
            :value="2"
            type="radio"
            :checked="dancerChoice == '2'"
            @input="updateDancerChoice($event)"
          />
          <strong>   {{ $t('follower') }}   </strong>
          </p>
          </div>
          <div>
          <p>
          <input
            id="two"
            :value="1"
            type="radio"
            :checked="dancerChoice == '1'"
            @input="updateDancerChoice($event)"
          />
          <strong>   {{ $t('leader') }}   </strong>
          </p>
          </div>
          
          <p>
          <input
            id="three"
            :value="3"
            type="radio"
            :checked="dancerChoice == '3'"
            @input="updateDancerChoice($event)"
          />
          <strong>  {{ $t('both') }}   </strong>
          </p>
          <div class="form-group">
            <p><span for="yoe"><strong>{{ $t('years_of_tango_experience_more _then') }}</strong></span></p>
            <Field
              name="yoe"
              as="select"
              class="form-control"
              
              v-model="request.yoe"
            >
              <option v-for="year in request.years" :value="year" :key="year">{{year}}
                
              </option>
            </Field>
            
          </div> 

          <div class="form-group">
            <label for="request_country"><strong>{{ $t('country') }}</strong>
              <i class="fa fa-asterisk fa red-color"></i>
            </label>
            <Field :validateOnChange="true" :validateOnInput="true"
              name="request_country"
              type="text" 
              class="form-control"
              v-model="request.request_country" rules="required"
            />
            <ErrorMessage name="request_country" class="error-feedback" />
          </div> 

          <div class="form-group">
            <label for="request_city"><strong>{{ $t('city') }}</strong>
              
            </label>
            <Field :validateOnChange="true" :validateOnInput="true"
              name="request_city"
              type="text" 
              class="form-control"
              v-model="request.request_city" 
            />
            
          </div>         

          <div class="form-group">
            <label for="request_city"><strong>{{ $t('region') }}</strong>
              
            </label>
            <Field :validateOnChange="true" :validateOnInput="true"
              name="request_region"
              type="text" 
              class="form-control"
              v-model="request.request_region" 
            />
            
          </div>                                       

          <div class="form-group" style="padding-left:5px;">
            <label for="date_From"><strong>{{ $t('search_for_dance_partner_for_month') }}</strong></label>

            <datepicker v-model="dateFrom" 
            minimum-view="month"  />
            <input 
              type="hidden"
              name="date_From"
              
            />
          </div>          

          <div class="form-group">
            <button class="badge badge-warning mr-2" style="font-size: .87em;" @click="cancelUpdate">
              {{ $t('cancel') }}
            </button>
            <button type="submit" class="badge badge-info" style="font-size: .87em;">{{ $t('new_request') }}</button>
          </div>
          <div
              v-if="getError().length > 0"
              class="alert alert-danger"
              role="alert"
          >
              {{ getError() }}
          </div>
        </div>
      </Form>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

import { directive } from 'vue-tippy'
import { required, min} from '@vee-validate/rules';
import datepicker from 'vue3-datepicker'
import { Form, Field, ErrorMessage, defineRule } from 'vee-validate'
defineRule('required', required);
defineRule('min', min);


export default {
  name: 'Request',
  components: {
    Form,
    Field,
    ErrorMessage,
    datepicker
  },
    directives: {
    tippy: directive,
  },

  data() {
    
    const request = {
      request_desc: '',
      request_country: '',
      request_city: '',
      request_region: '',
      selectedFrom: '',
      owner_id: this.$store.state.user.profile.id,
      years: [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15],
      id: null,
      location: {},
      
      yoe: 0,
    }
    return {
      profile: this.$store.state.user.profile,
      request,
      submitted: false,
      successful: false,
      message: '',
      dateFrom: new Date(),
  
    }
  },
  computed: {
    dancerChoice() {
      return this.$store.state.product.dancerChoice
    },   
  },
  methods: {
    ...mapActions(['cancelUpdate']),

    changeFromChild(event) {
      this.message = event

      this.$store.commit('ERROR', event)      
    },
    getError() {
      return this.$store.state.profiles.message
    }, 
    updateDancerChoice($event) {
      this.$store.commit('setDancerChoice', $event.target.value)

    },    
    async saveRequest(request) {
      await this.$store
        .dispatch('updateRequest', request)

        .then(() => {
          
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    formateDateFrom() {

      const yyyy = this.dateFrom.getFullYear();
      let mm = this.dateFrom.getMonth() + 1; // month is zero-based
      if (mm < 10) mm = '0' + mm;
      const formatted = mm + '-' + yyyy;
      this.$store.commit('setSelectedFrom', formatted)
           
    },    
    addRequest(request) {
      request.selectedFrom = this.$store.state.product.selectedFrom
      request.location.country = request.request_country
      request.location.city = request.request_city
      request.location.region = request.request_region
      request.dancerChoice = this.$store.state.product.dancerChoice
      if (!request.location.city && !request.location.region) {
        this.$store.commit('ERROR', "Please, choose city or region")  
        return false
      }
      else {
        this.saveRequest(request);
      }

    },
         
  },

  watch: {
    dateFrom() {
      this.formateDateFrom()
    },
  },  

  created() {
    this.message = ''
    this.formateDateFrom()

  },  

}
</script>

<style>
.submit-form {
  max-width: 300px;
  margin: auto;
}
</style>
