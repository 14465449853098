<template>
  <div class="row">
    <div class="col">
    
      <router-link :to="'/places/tango/' + tango.id">
        <p><strong>{{ tango.name }}</strong></p>
      </router-link>  

      <img :src="getImgPhoto(tango)" style="padding-left: 10px" />


    
      <span v-if='isAdmin' @click="deletePlace(tango)"
        ><router-link to="/account" class="badge badge-info"
          >{{ $t('delete') }}</router-link
        ></span
      >
    </div>   
  </div>

</template>
<script>
import { mapGetters } from 'vuex'
import { PLACE_DELETE } from '/static/actions/place'
export default {
  name: 'Tango',

  props: ['tango'],
  data() {
    return {
      notes: [],
      pid: this.$store.state.user.profile.id,
    }
  },
  computed: {
    ...mapGetters(['isAdmin']),
  },
  methods: {
    getImgPhoto(obj) {
      return obj.photo
    },
    async deletePlace(place) {
      const curPlace = {userid: this.pid, owner_id: place.owner_id, place_id: place.id }
      
      await this.$store
        .dispatch(PLACE_DELETE, curPlace)
        .then(() => {
          // this.message = this.$store.state.profiles.message)
        })  
        .catch(function (error) {
          console.log(error)
        })
    
    },      
  },
  created() {},
}


</script>

<style scoped>
  p { font-size: 130%;}
</style>  
