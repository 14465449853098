<template>
  <div class="box">
    <div class="cart-item__details">
      <p class="is-inline">{{ cartItem.title }}</p>
      <div class="media">
        <div class="media-left">
          <span class="cart-item--price has-text-info has-text-weight-bold">
            ${{ cartItem.price }} X {{ cartItem.quantity }}
          </span>
        </div>
        <div class="media-right">
          <span class="icon" @click="addCartItem(cartItem)">
            <i class="fas fa-arrow-circle-up cart-item__modify"></i>
          </span>
          <span class="icon" @click="removeCartItem(cartItem)">
            <i class="fas fa-arrow-circle-down cart-item__modify"></i>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
export default {
  name: 'CartListItem',
  props: ['cartItem'],
  methods: {
    ...mapActions(['addCartItem', 'removeCartItem']),
  },
}
</script>
