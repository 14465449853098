<template>

  <div v-if="Array.isArray(dancers) && dancers[0]">
    <div class="card" v-for="dancer in pageOfItems" :key="dancer.id">
      <Dancer :dancer="dancer" />
    </div>
    <div class="card">
      <JwPagination :items="dancers" @changePage="onChangePage"></JwPagination>
    </div>
  </div>

  <div v-else> <p style="color:white;">{{ $t('not_found') }} </p> </div>
</template>
<script>
//import { mapGetters } from 'vuex';
import Dancer from './Dancer'
import i18n from '@/i18n'

import JwPagination from '../core/JwPagination'
export default {
  name: 'DancerList',
  components: {
    Dancer,
    JwPagination,
  },
  data() {
    return {
      pageOfItems: [],
    }
  },
  computed: {
    //...mapGetters(['petChoice']),
    choice () {
      return this.choiceTranslate()
    },
    dancers() {
      let dancers = this.$store.state.product.dancers
      return dancers
    },
  },
  methods: {
    choiceTranslate() {
      let ch = this.$store.state.product.dancerChoice
      if (ch == 1) {
        return i18n.global.t('leader')
      }
      else if (ch == 2) {
        return i18n.global.t('follower')
      }
      else if (ch == 3) {
        return i18n.global.t('both')
      }

      return ''
    },  
    filterAnimalsAgain(pet) {
      return new Date(pet.need.from_date).getMonth() == new Date().getMonth()
    },

    getImgPhoto(obj) {
      return obj.photo
    },
    onChangePage(pageOfItems) {
      // update page of items
      this.pageOfItems = pageOfItems
    },
    formatDate(date) {
      return new Intl.DateTimeFormat('en-US', { dateStyle: 'long' }).format(
        new Date(date)
      )
    },
    currentWeekDate() {
      const current = new Date()
      const date =
        current.getFullYear() +
        '-' +
        (current.getMonth() + 1) +
        '-' +
        current.getDate()
      // const time = current.getHours() + ":" + current.getMinutes() + ":" + current.getSeconds();
      // const dateTime = date +' '+ time;
      // const date = `${current.getDate()}/${current.getMonth()+1}/${current.getFullYear()}`;
      current.setDate(new Date(date).getDate() - 7)
      return this.formatDate(current)
    },
  },
  created() {
    // this.$store.commit('setSelectedCity', '')
    
  },
}
</script>

<style>
.card {
  background-color: grey !important;
}
</style>
