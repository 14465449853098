<template>
  <div class="card card-container">
  <p><strong>{{ $t('add_place') }}</strong></p>
    <div v-if="!submitted" class="col-md-12">
      <Form
        @submit="addPlace"
        :initial-values="place"
        ref="olaceForm"
      >
      <div class="form-group">
        <span name="photo"  v-tooltip="`${$t('profile_photo_tip')}`"><strong>{{ $t('photo') }}</strong>
          <i class="fa fa-asterisk fa red-color"></i>
        </span>        
          <photo-upload name="photo"
            ref="uploadPhotos"
            @changeMessage="changeFromChild($event)"
          />
          <span role="alert" class="error-feedback" name="photo_error"
            >{{ this.message }}
          </span>
      </div>    
        <div class="form-group">
          <label for="place_name"><strong>{{ $t('place_name') }}</strong>
            <i class="fa fa-asterisk fa red-color"></i>
          </label>
          <Field :validateOnChange="true" :validateOnInput="true"
            name="place_name"
            type="text" 
            class="form-control"
            v-model="place.place_name" rules="required"
          />
          <ErrorMessage name="place_name" class="error-feedback" />
        </div>       
        <div v-if="!successful">
          <div class="form-group">
            <label for="place_desc"><strong>{{ $t('place_desc') }}</strong>
              <i class="fa fa-asterisk fa red-color"></i>
            </label>

          <Field v-slot="{ field, errors }" v-model="place.place_desc" name="place_desc" rules="required">
            <textarea v-bind="field" name="place_desc" cols="25" rows="4"/>
            <div v-if="errors[0]" class="error-feedback">{{errors[0]}}</div>
          </Field>    
 
            
          </div>

          <div class="form-group">
            <label for="place_country"><strong>{{ $t('country') }}</strong>
              <i class="fa fa-asterisk fa red-color"></i>
            </label>
            <Field :validateOnChange="true" :validateOnInput="true"
              name="place_country"
              type="text" 
              class="form-control"
              v-model="place.place_country" rules="required"
            />
            <ErrorMessage name="place_country" class="error-feedback" />
          </div> 

          <div class="form-group">
            <label for="place_region"><strong>{{ $t('region') }}</strong>
              
            </label>
            <Field :validateOnChange="true" :validateOnInput="true"
              name="place_region"
              type="text" 
              class="form-control"
              v-model="place.place_region" 
            />
            <ErrorMessage name="place_street" class="error-feedback" />
          </div>               

          <div class="form-group">
            <label for="place_city"><strong>{{ $t('city') }}</strong>
              <i class="fa fa-asterisk fa red-color"></i>
            </label>
            <Field :validateOnChange="true" :validateOnInput="true"
              name="place_city"
              type="text" 
              class="form-control"
              v-model="place.place_city" rules="required"
            />
            <ErrorMessage name="place_city" class="error-feedback" />
          </div>                           

          <div class="form-group">
            <label for="place_street"><strong>{{ $t('street') }}</strong>
              <i class="fa fa-asterisk fa red-color"></i>
            </label>
            <Field :validateOnChange="true" :validateOnInput="true"
              name="place_street"
              type="text" 
              class="form-control"
              v-model="place.place_street" rules="required"
            />
            <ErrorMessage name="place_street" class="error-feedback" />
          </div>     

          <div class="form-group">
            <button class="badge badge-warning mr-2" style="font-size: .87em;" @click="cancelUpdate">
              {{ $t('cancel') }}
            </button>
            <button type="submit" class="badge badge-info" style="font-size: .87em;">{{ $t('new_place') }}</button>
          </div>
          <div
              v-if="getError().length > 0"
              class="alert alert-danger"
              role="alert"
          >
              {{ getError() }}
          </div>
        </div>
      </Form>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import PhotoUpload from '../core/PhotoUpload'
import { directive } from 'vue-tippy'
import { required, min} from '@vee-validate/rules';

import { Form, Field, ErrorMessage, defineRule } from 'vee-validate'
defineRule('required', required);
defineRule('min', min);


export default {
  name: 'Place',
  components: {
    Form,
    Field,
    ErrorMessage,
    PhotoUpload,
  },
    directives: {
    tippy: directive,
  },

  data() {
    
    const place = {
      place_desc: '',
      place_country: '',
      place_city: '',
      place_street: '',
      place_region: '',
      place_name: '',
      owner_id: this.$store.state.user.profile.id,
      id: null,
      location: {},
    }
    return {
      profile: this.$store.state.user.profile,
      place,
      submitted: false,
      successful: false,
      message: '',

    }
  },

  methods: {
    ...mapActions(['cancelUpdate']),
    changeFromChild(event) {
      this.message = event

      this.$store.commit('ERROR', event)      
    },
    getError() {
      return this.$store.state.profiles.message
    }, 
    async savePlace(place) {

      let photos = []
      if (
        Array.isArray(this.$refs.uploadPhotos.photos) &&
        this.$refs.uploadPhotos.photos[0]
      ) {
        photos = photos.concat(this.$refs.uploadPhotos.photos)

        place.filedata = photos[0].data
        place.filename = photos[0].name
      } else {

        this.message += this.$t('profile_photo')
        return
      }      
      await this.$store
        .dispatch('updatePlace', place)

        .then(() => {

          // this.message = this.$store.state.profiles.message
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    addPlace(place) {
      place.location.street = place.place_street
      place.location.country = place.place_country
      place.location.city = place.place_city
      place.location.region = place.place_region

      this.savePlace(place);

    },
         
  },

  created() {
    this.message = ''

  },  

}
</script>

<style>
.submit-form {
  max-width: 300px;
  margin: auto;
}
</style>
