<template>
  <div class="col-md-12">
    <div class="card card-container">
      <Form
        @submit="handlePassword"
        
        ref="chPswdForm"
      >
        <div>
          <div class="form-group">
            <label for="email">{{ $t('email') }}</label>
            <Field name="email" type="email" class="form-control" rules="required|email" :validateOnChange="true" :validateOnInput="true" />
            <ErrorMessage name="email" class="error-feedback" />
          </div>

          <div class="form-group">
            <label for="curpassword">{{ $t('current_password') }}</label>
            <Field name="curpassword" type="text" class="form-control" />
          </div>
          <div class="form-group">
            <label for="password">{{ $t('new_password') }}</label>
            <Field name="password" type="password" class="form-control" rules="required|min:6" ref="password" :validateOnChange="true" :validateOnInput="true"/>
            <ErrorMessage name="password" class="error-feedback" />
          </div>
          <div class="form-group">
            <label for="password_confirmation">{{ $t('confirmed_password') }}</label>
            <Field rules="required|confirmed:@password" :validateOnChange="true" :validateOnInput="true"
              name="password_confirmation"
              type="password"
              class="form-control"
            />
            <ErrorMessage name="password_confirmation" class="error-feedback" />
          </div>

          <div class="form-group">
            <button class="btn btn-info btn-block" :disabled="loading">
              {{ $t('change_password') }}
            </button>
          </div>
        </div>

        <div class="form-group">
          <div v-if="message" class="alert alert-danger" role="alert">
            {{ message }}
          </div>
        </div>
      </Form>
    </div>
  </div>
</template>

<script>
import { directive } from 'vue-tippy'
import { required, confirmed, min, email, max} from '@vee-validate/rules';


import { Form, Field, ErrorMessage, defineRule } from 'vee-validate'
defineRule('required', required);
defineRule('confirmed', confirmed);
defineRule('min', min);
defineRule('max', max);
defineRule('email', email);

import i18n from '@/i18n'

export default {
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  directives: {
    tippy: directive,
  },
  data() {
    return {
      loading: false,

      message: '',
    }
  },
  methods: {
    async handlePassword(user) {
      try {
        const pr = await this.$store.dispatch('changePassword', user)
        if (!pr) {
          this.message = i18n.global.t('cannot_change_password') 
        }
      } catch {
        throw new Error(i18n.global.t('invalid_request'))
      }
    },
  },
}
</script>
