<template>
  <div class="product-page">
    <div class="sidebar-wrap">
      <Sidebar />
    </div>
    <div class="product-list-wrap" >
      
        <router-view />
      
    </div>
  </div>
</template>

<script>
import Sidebar from './Sidebar'

export default {
  components: {
    Sidebar,
  },
}
</script>

<style>
.product-page {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.sidebar-wrap {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  padding-right: 10px;
  border-right: 1px solid #eee;
}
.product-list-wrap {
  -webkit-box-flex: 4;
  -ms-flex: 4;
  flex: 4;
  padding-left: 10px;
}
</style>
