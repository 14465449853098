<template>
  <div class="row">
    <div class="col">
        <router-link :to="'/dancers/dancer/'+ dancer.id" style="margin-right: 10px;">
          <p><strong>{{ dancer.name }} </strong></p>
        </router-link> 
         
       
        <img :src="getImgPhoto(dancer)" style="padding-left: 3px"/>

    </div>    
  </div>
  

</template>
<script>

export default {
  name: 'Dancer',
  props: ['dancer'],
  methods: {
  
    getImgPhoto(obj) {
      return obj.photo
    },
  },
  created() {
    //this.owner = this.$store.dispatch('getOwner', pet);
    //this.$store.commit('setPet', this.pet)
    //localStorage.setItem('pet', this.pet)
  },
}
</script>

<style scoped>
  p { font-size: 130%;}
</style>  


